import React, { useMemo, useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';

import './DashboardTable.scss';
import { toast } from 'react-toastify';
import { SortIcon } from '../../assets/images';
import { NoDocumentsFound } from '../../components/General';
import CONSTANTS from '../../constants';
import { GROUP_APPROVALS_COLUMN } from './TableColumns';
import Pagination from '../../components/pagination/Pagination';
import Filters from '../../components/filters/Filters';
import {
    myGroupApprovalsConfig,
    myGroupApprovalsDefault,
} from '../../components/filters/filter-config';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IGroupApproval } from '../../interfaces/dashboard';
import { getGroupApprovals } from '../../API/dashboard';
import STATIC_CONTENT from '../../constants/StaticContent';

const DEFAULT_PAGINATION_DATA: IPaginationData = {
    pageIndex: 0,
    pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
    totalCount: 0,
};

const DEFAULT_SORT: ISort = {
    id: 'createdDate',
    desc: true,
};

const columnMapping: IStringObject = {
    docNumber: 'documentNumber',
    title: 'documentTitle',
    createdDate: 'startTime',
    authorName: 'documentAuthor',
    approverState: 'approverState',
    // deadline: 'deadline'
};

const defaultGroupApprovals = () => {
    const groupApprovals: IGroupApproval[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 9; i++) {
        groupApprovals.push({
            taskId: CONSTANTS.LOADING.TEXT,
            authorName: CONSTANTS.LOADING.TEXT,
            authorId: CONSTANTS.LOADING.NUMBER,
            approverState: 'NEW',
            assigneeId: CONSTANTS.LOADING.NUMBER,
            groupName: CONSTANTS.LOADING.TEXT,
            docId: CONSTANTS.LOADING.NUMBER,
            docNumber: CONSTANTS.LOADING.TEXT,
            title: CONSTANTS.LOADING.TEXT,
            createdDate: CONSTANTS.LOADING.DATE,
            // deadline: CONSTANTS.LOADING.DATE,
            files: CONSTANTS.LOADING.NUMBER,
            isConfidential: false,
            approver: CONSTANTS.LOADING.TEXT,
        });
    }

    return groupApprovals;
};

// eslint-disable-next-line max-lines-per-function
const MyGroupApprovalsTab: React.FC = () => {
    const columns = useMemo(() => GROUP_APPROVALS_COLUMN, []);
    const [filters, setFilters] = useState<IStringObject>({});
    const [groupApprovals, setGroupApprovals] = useState(defaultGroupApprovals());
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        gotoPage,
        setSortBy,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IGroupApproval>(
        {
            columns,
            data: groupApprovals,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    const fetchGroupApprovals = async () => {
        const filtersString = JSON.stringify(filters);
        if (filtersString === '{}') {
            return;
        }
        const queryParams = {
            ...myGroupApprovalsDefault,
            ...filters,
        };

        const response = await getGroupApprovals(
            queryParams as IStringObject,
            {
                sortBy: columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'documentTitle',
                sortOrder: sortBy[0].desc ? 'desc' : 'asc',
            },
            pageSize,
            pageIndex * pageSize,
        );
        if (response?.apiStatus === 'SUCCESS') {
            setGroupApprovals(response.groupApprovals);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: response.totalDocuments,
            });
        } else {
            toast.error(CONSTANTS.ERROR_406);
        }
    };

    const resetFilters = () => {
        setFilters(myGroupApprovalsDefault);
        gotoPage(DEFAULT_PAGINATION_DATA.pageIndex);
        setPageSize(DEFAULT_PAGINATION_DATA.pageSize);
        setSortBy([DEFAULT_SORT]);
    };

    const filterSearch = (queryParam: IStringObject) => {
        setFilters(queryParam);
        gotoPage(DEFAULT_PAGINATION_DATA.pageIndex);
        setPageSize(DEFAULT_PAGINATION_DATA.pageSize);
        setSortBy([DEFAULT_SORT]);
    };

    useEffect(() => {
        fetchGroupApprovals();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy, filters]);

    return (
        <div className="my-approvals-tab">
            <div className="filters-section">
                <Filters
                    filtersConfig={myGroupApprovalsConfig}
                    filterSearch={filterSearch}
                    resetFilters={resetFilters}
                    documentCount={pagination.totalCount}
                    loading={false}
                />
            </div>
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => {
                            const { key: headerGroupKey, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <tr
                                    key={headerGroupKey}
                                    {...restHeaderGroupProps}
                                >
                                    {headerGroup.headers.map((column) => {
                                        const sortByProps = column.getSortByToggleProps();
                                        const headerProps = column.getHeaderProps();
                                        const { key: keyValue, ...restHeaderProps } = headerProps;
                                        return (
                                            <th
                                                key={keyValue}
                                                {...sortByProps}
                                                {...restHeaderProps}
                                                style={{
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                }}
                                            >
                                                {column.render('Header')}
                                                {column.isSorted && (
                                                    <SortIcon
                                                        className={`sort ${
                                                            column.isSortedDesc ? 'desc' : 'asc'
                                                        } `}
                                                    />
                                                )}
                                            </th>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            const { key: rowKey, ...restRowProps } = row.getRowProps();
                            return (
                                <tr key={rowKey} {...restRowProps}>
                                    {row.cells.map((cell) => {
                                        const cellProps = cell.getCellProps();
                                        const { key: keyValue, ...restCellProps } = cellProps;
                                        return (
                                            <td
                                                key={keyValue}
                                                className={cell.column.id}
                                                {...restCellProps}
                                                style={{
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                }}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                )}
                {page.length === 0 && (
                    <NoDocumentsFound heading={STATIC_CONTENT.DASHBOARD.NO_DOCUMENT.MY_APPROVALS} />
                )}
            </div>
        </div>
    );
};

export default MyGroupApprovalsTab;
