/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Column, Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { ISunsetDocs, ISunsetTasks } from '../../interfaces/sunset';
import { SkeletonText } from '../../components/Skeleton';
import { DateColumn, StatusColumn } from '../../components/General';
import { DocumentIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import { getDocument } from '../../API/document';

const DATE_MIN_WIDTH = 150;

const DocNumberForSunsetDocs = (row: Row<ISunsetDocs>) => {
    const navigate = useNavigate();
    const {
        original: { docNumber, docId, activeStatus },
    } = row;
    const onClick = () => {
        navigate({
            pathname:
                activeStatus === 'DRAFT'
                    ? `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}`
                    : `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
            search: `?doc_id=${docId}`,
        });
    };
    return (
        <div className="doc-number-column">
            <h4
                className="link-style"
                onClick={() => onClick()}
                onKeyPress={() => onClick()}
                role="presentation"
            >
                <SkeletonText text={docNumber} width={200} />
            </h4>
        </div>
    );
};

const FilesColumn = ({ value }: { value: number }) => (
    <div className="files-column">
        {value ? (
            <>
                {value} <DocumentIconSvg />{' '}
            </>
        ) : (
            <>-</>
        )}
    </div>
);

const TitleSunsetDocsColumn = (row: Row<ISunsetDocs>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

export const MY_SUNSET_DOCS_COLUMN: Column<ISunsetDocs>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberForSunsetDocs {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleSunsetDocsColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Published On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        disableSortBy: true,
        minWidth: 15,
    },
    {
        Header: 'New Version Status',
        accessor: 'activeStatus',
        Cell: ({ value }) => (value ? <StatusColumn value={value} /> : '-'),
        minWidth: 140,
    },
    {
        Header: 'Last Published Status',
        accessor: 'lastPublishedStatus',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
];

const DocNumberForSunsetTasks = (row: Row<ISunsetTasks>) => {
    const navigate = useNavigate();
    const {
        original: { docNumber, docId, activeVersionId },
    } = row;
    const onClick = async () => {
        const res = await getDocument(docId, false);
        const state = res?.documentInfo.state;
        let pathname;
        if (state === 'DRAFT') {
            pathname = `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}`;
        } else {
            pathname = `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`;
        }
        navigate({
            pathname,
            search: `?doc_id=${docId}&docVersionId=${activeVersionId}`,
        });
    };
    return (
        <div className="doc-number-column">
            <h4
                className="link-style"
                onClick={() => onClick()}
                onKeyPress={() => onClick()}
                role="presentation"
            >
                <SkeletonText text={docNumber} width={200} />
            </h4>
        </div>
    );
};

const TitleSunsetTasksColumn = (row: Row<ISunsetTasks>) => {
    const {
        original: { title },
    } = row;

    return (
        <>
            {/* {isConfidential && <span className="confidential">Confidential: </span>} */}
            <SkeletonText text={title} width={200} />
        </>
    );
};

export const MY_SUNSET_TASK_COLUMN: Column<ISunsetTasks>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberForSunsetTasks {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleSunsetTasksColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Sunset On',
        accessor: 'sunsetDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },

    {
        Header: 'Status',
        accessor: 'action',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
];
