import React, {useState, useEffect, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { Column, usePagination, useTable, useSortBy } from 'react-table';
import Skeleton from 'react-loading-skeleton';
import { SortIcon } from '../../assets/images';
import { fetchDocumentHistory } from '../../API/document';
import { NoHistoryFound, DateColumn, StatusColumn } from '../General';
import { IDocumentHistory } from '../../interfaces/document';
import CONSTANTS from '../../constants';
import Pagination from '../pagination/Pagination';
import './DocumentHistory.scss';

interface Props{
    documentId: number;
}

const SkeletonFields = (): JSX.Element => (
    <div className="full">
        <div>
            <Skeleton width="100%" />
        </div>
    </div>
);

const DocumentPhase = ({value}: {value:string}): JSX.Element => (
    <div className={`status-pill document-phase ${value.toLowerCase()}`}> {value} </div>
);

const COLUMNS : Column<IDocumentHistory>[] = [
    {
        Header: 'Change Number',
        accessor: 'changeNumber',
        Cell: ({ value, row }) => {
            const {original: { docId }} = row;
            return (
                <Link
                    className="link-style"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                    to={{
                        pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                        search: `?doc_id=${docId}`,
                    }}
                >{value}</Link>
            )
        }   
    },
    {
        Header: 'Revision',
        accessor: 'revision',
    },
    {
        Header: 'Revision Status',
        accessor: 'revisionStatus',
        Cell: ({ value }) => <StatusColumn value={value} />
    },
    {
        Header: 'Effective Date',
        accessor: 'effectiveDate',
        Cell: ({ value }) => <DateColumn value={value} />
    },
    {
        Header: 'Phase',
        accessor: 'phase',
        Cell: ({ value }) => <DocumentPhase value={value} />
    },
    {
        Header: 'Creator',
        accessor: 'creator',
    },
    {
        Header: 'Notes',
        accessor: 'notes',
    }
]

// eslint-disable-next-line max-lines-per-function
const DocumentHistory: React.FC<Props> = ({documentId}) => {
    const [documentHistory, setDocumentHistory] = useState<IDocumentHistory[]>([]);
    const [loader, setLoader] = useState(true);
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: documentHistory || [],
            initialState: {
                sortBy: [
                    {
                        id: 'changeNumber',
                        desc: true
                    }
                ]
            }
        },
        useSortBy,
        usePagination
    );

    const getDocumentHistory = async () => {
        const res = await fetchDocumentHistory(documentId);
        setLoader(false);
        setDocumentHistory(res?.data? res.data: []);
    }

    useEffect(() => {
        getDocumentHistory();
    }, []);

    return (
        <div className="document-history">
            
            {loader && (
                <div className="loading-state">
                    <SkeletonFields />
                    <SkeletonFields />
                    <SkeletonFields />
                </div>
            )}

            {!loader && (documentHistory?.length === 0) && <NoHistoryFound />}

            {(!loader && documentHistory?.length) ? (
                <div className="table-section">
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, i) => (
                                        <th 
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            {...column.getHeaderProps({style: {textAlign: 'left'}})}
                                        >
                                            {column.render('Header')}
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto'
                                                    },
                                                })}>
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <Pagination
                        nextPage={nextPage}
                        canNextPage={canNextPage}
                        canPreviousPage={canPreviousPage}
                        pageIndex={pageIndex}
                        pageOptions={pageOptions}
                        pageSize={pageSize}
                        previousPage={previousPage}
                        setPageSize={setPageSize}
                    />
                </div>
            ): null}
        </div>
    )
};

export default DocumentHistory;