import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import 'react-popper-tooltip/dist/styles.css';

import { IDocReferenceInfo, IField, IFieldsData } from '../../interfaces/document';
import { IUiControlTypes } from '../../interfaces';
import {
    Text,
    SingleSelect,
    TextBox,
    Number,
    DatePicker,
    MultiSelectDropdown,
    TextBoxWithChip,
    DocumentReference,
    Float
} from '../input-fields';
import ToggleInput from '../input-fields/toggle/ToggleInput';
import './ImportantFields.scss';
import { IDocumentAction } from '../../reducers/document-reducer';
import ButtonSelector from '../input-fields/button-selector/ButtonSelector';
import { InvalidSvg } from '../../assets/images';
import Markup from '../input-fields/markup/Markup';
// eslint-disable-next-line import/no-cycle
import LinkedDocuments from '../input-fields/linked-documents/LinkedDocuments';
import DatePickerDateOnly from '../input-fields/date-picker-date-only/DatePickerDateOnly';

interface IInputComponentProps {
    componentType: IUiControlTypes;
    isDisabled: boolean;
    field: IField;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
}

// eslint-disable-next-line max-lines-per-function
const InputComponent: React.FC<IInputComponentProps> = ({
    componentType,
    isDisabled,
    field,
    fieldsData,
    documentDispatch,
    errorFields,
}: IInputComponentProps) => {
    const [fieldData] = fieldsData.filter((a) => a.name === field.name);
    switch (componentType) {
        case 'Text':
            return (
                <Text
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Text Box':
            return (
                <TextBox
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Single Select Dropdown':
            return (
                <SingleSelect
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Multi Select Dropdown':
            return (
                <MultiSelectDropdown
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Number':
            return (
                <Number
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as number) : 0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Float':
            return (
                <Float
                    documentDispatch={documentDispatch || null}
                    field={field}
                    step={field.step}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as number) : 0.0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'DatePicker':
            return (
                <DatePicker
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? new Date(fieldData.value as Date) : new Date()}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'OnlyDate':
            return (
                <DatePickerDateOnly
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? fieldData.value : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Text Box With Chip':
            return (
                <TextBoxWithChip
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Toggle':
            return (
                <ToggleInput
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as boolean) : false}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Button Select':
            return (
                <ButtonSelector
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Markup':
            return <Markup field={field} />;
        case 'Table':
            return (
                <DocumentReference
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as IDocReferenceInfo[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Reference':
            return (
                <LinkedDocuments
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                    isEditable={documentDispatch !== null}
                    isChild
                />
            );
        default:
            return <div>something is wrong</div>;
    }
};

interface FieldsToolTipProps {
    isDisabled: boolean;
    field: IField;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
}

// eslint-disable-next-line max-lines-per-function
const FieldsToolTip = ({
    isDisabled,
    field,
    fieldsData,
    documentDispatch,
    errorFields,
}: FieldsToolTipProps) => {
    const halfUiControl: IUiControlTypes[] = [
        'OnlyDate',
        'DatePicker',
        'Number',
        'Single Select Dropdown',
        'Toggle',
    ];
    return (
        <>
            <div data-for="input-component" data-tip={!isDisabled ? field.helperText : null} style={{ width: '100%' }}>
                <>
                    <InputComponent
                        componentType={field.uiControl.label}
                        isDisabled={isDisabled}
                        field={field}
                        documentDispatch={documentDispatch}
                        fieldsData={fieldsData}
                        errorFields={errorFields || []}
                    />
                </>
            </div>
        </>
    );
};

interface Props {
    isDisabled: boolean;
    fields: IField[];
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    // eslint-disable-next-line react/require-default-props
    errorFields?: string[];
}

const SkeletonImportantFields = (): JSX.Element => (
    <div className="full">
        <label htmlFor="">
            <Skeleton />
        </label>
        <div>
            <Skeleton width="80%" />
        </div>
    </div>
);

// eslint-disable-next-line max-lines-per-function
const ImportantFieldsDupl: React.FC<Props> = ({
    isDisabled,
    fields,
    fieldsData,
    documentDispatch,
    errorFields,
}: Props) => (
    <div className="important-fields">
        {fields.length > 0 ? (
            fields.map((field) => (
                <FieldsToolTip
                    key={field.id}
                    isDisabled={isDisabled}
                    field={field}
                    documentDispatch={documentDispatch}
                    fieldsData={fieldsData}
                    errorFields={errorFields || []}
                />
            ))
        ) : (
            <>
                <SkeletonImportantFields />
                <SkeletonImportantFields />
                <SkeletonImportantFields />
            </>
        )}
    </div>
);

export default ImportantFieldsDupl;
