import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllDocumentTypes } from '../../API/document-type';
import { GridViewIcon, ListVIewIcon } from '../../assets/images';
import { IDocTypeCard } from '../../interfaces/document-type';
import DocTypeGridView from '../../widgets/doc-type-views/DocTypeGridView';
import DocTypeListView from '../../widgets/doc-type-views/DocTypeListView';
import './DocumentTypes.scss';
import STATIC_CONTENT from '../../constants/StaticContent';

const { DOCUMENT_TYPES } = STATIC_CONTENT;
// eslint-disable-next-line max-lines-per-function
const DocumentTypes: React.FC = () => {
    const [activeTab, setActiveTab] = useState<'GRID' | 'LIST'>('GRID');
    const [docTypeData, setDocTypeData] = useState<IDocTypeCard[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const res = await getAllDocumentTypes();
            if (res?.apiStatus === 'SUCCESS') {
                setDocTypeData(res.docTypeCard);
            }
        };
        fetchData();
    }, []);

    const createDocType = async () => {
        navigate(`/document-type/create`);
    };
    return (
        <div className="document-types">
            <div className="top-section">
                <div className="title">
                    <h1>{DOCUMENT_TYPES.LABEL.DOCUMENT_TYPES}</h1>
                    <h6>{DOCUMENT_TYPES.LABEL.SELECT_DOCUMENT}</h6>
                </div>
                <div className="buttons">
                    <button type="button" className="primary-btn" onClick={() => createDocType()}>
                        {DOCUMENT_TYPES.BUTTON.CREATE_NEW}
                    </button>
                </div>
                <div className="tab">
                    <button
                        type="button"
                        className={`${activeTab === 'GRID' ? 'active' : ''}`}
                        onClick={() => setActiveTab('GRID')}
                    >
                        <GridViewIcon />
                    </button>
                    <button
                        type="button"
                        className={`${activeTab === 'LIST' ? 'active' : ''}`}
                        onClick={() => setActiveTab('LIST')}
                    >
                        <ListVIewIcon />
                    </button>
                </div>
            </div>
            {activeTab === 'GRID' ? (
                <DocTypeGridView docTypeData={docTypeData} />
            ) : (
                <DocTypeListView docTypeData={docTypeData} />
            )}
        </div>
    );
};

export default DocumentTypes;
