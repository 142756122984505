/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { createContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocumentTypeInfo } from '../../API/document';
import { SuccessfulIcon } from '../../assets/images';
import { NoPermission } from '../../components/General';
import ActivitySection from '../../components/activity-section/ActivitySection';
import ViewAttachments from '../../components/attachments/view/ViewAttachments';
import Button from '../../components/button/Button';
import ChecklistComponent from '../../components/checklist/checklistComponent';
import ConfirmationModal, {
    IAcceptedType,
} from '../../components/confirmation-modal/ConfirmationModal';
import DocumentAction from '../../components/document-action/DocumentAction';
import DocumentHistory from '../../components/document-history/DocumentHistory';
import ImportantFields from '../../components/important-fields/ImportantFields';
import ImportantFieldsEditable from '../../components/important-fields/ImportantFieldsEditable';
import ViewLinkedFiles from '../../components/input-fields/file-type-docs/ViewLinkedFiles';
import LinkedDocuments from '../../components/input-fields/linked-documents/LinkedDocuments';
import RefDocsTreeNodes from '../../components/reference-documents-tree/RefDocsTreeNodes';
import Reviewers from '../../components/reviewers/Reviewers';
import { ExternalStageCard, StageCard } from '../../components/work-flow-panel/WorkFlowPanel';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import DocumentProvider, { useDocumentContext } from '../../contexts/document-provider';
import EventsHeightProvider, { useEventsHeight } from '../../contexts/events-height-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IButtonStatus, IDocStatus } from '../../interfaces';
import { IApprovalDocStage } from '../../interfaces/document';
import { removeFromLocalStorage, setLocalStorage } from '../../utils/local-storage';
import DocComments from '../../widgets/doc-comment/DocComments';
import DocDetailTimeline from '../../widgets/doc-detail-events/DocDetailEvents';
import CheckoutConfirmationModel from '../../widgets/document-info/CheckoutConfirmationModel';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';
import './DocumentDetails.scss';

interface SidebarProps {
    height: number;
    documentId: number;
    allowReviseByAnyone: boolean;
    approvers: IApprovalDocStage[];
    docState: IDocStatus;
}

const Sidebar: React.FC<SidebarProps> = ({
    height,
    documentId,
    allowReviseByAnyone,
    approvers,
    docState,
}): JSX.Element => (
    <div className="sidebar" style={{ height: `${height}px` }}>
        <DocDetailTimeline
            docId={documentId}
            allowReviseByAnyone={allowReviseByAnyone}
            approvers={approvers}
        />
    </div>
);
export const HeightContext = createContext({ updatePosition: () => { } });
const { DOCUMENT_INFO, CREATE_DOCUMENTS } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const DocumentDetails: React.FC = () => {
    const {
        docInfo,
        userType,
        fields,
        fieldsData,
        documentId,
        selectedDocVersion,
        docVersions,
        approvers,
        approversDispatch,
        externalApprovers,
        reviewers,
        docTypeInfo,
        docTitleError,
        docDescError,
        activeDocVersionId,
        attachmentConfig,
        setSelectedDocVersion,
        setDocInfo,
        hideUsers,
        collectComment,
        collectCommentRejection,
        setHideUsers,
        fetchDocumentVersion,
        canViewDocumentDetails,
        documentDispatch,
        publishOnceApproved,
        isSunsetEnabled,
        activeVersionOwnerInfo,
        checkInVersionOwner,
        isEndDateAsked,
        isGroupMember,
        externalApproversDispatch,
        relationArr,
        setRelationArr,
        latestrelationArr,
        setlatestrelationArr,
        activeVersionApprovalDeadline,
        setActiveVersionApprovalDeadline,
        saveFieldsData,
        existingContract,
        onDocumentSubmit,
        submitDocStatus,
        docVersionCheckedInFlag,
        setDocVersionCheckedInFlag,
        onDocumentCheckIn,
        error: {
            STEP1: { errorFields },
        },
        delegatorInfo,
        taskId,
        accessibleByCurrentUser,
        checklist
    } = useDocumentContext();
    const { heightRef, height, updateHeight } = useEventsHeight();
    const isDocusignTriggered = approvers[0]?.approvers[0]?.docuSignTriggered
        ? approvers[0].approvers[0].docuSignTriggered
        : false;
    const { search } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        updateHeight();
    });
    const queryParams = new URLSearchParams(search);
    const docNumberValue = queryParams.get('doc_id');
    const fetchDocumentTypeInfo = async (val: number) => {
        if (val) {
            const res = await getDocumentTypeInfo(Number(val));
            if (res?.docTypeConfig?.isDCO) {
                setLocalStorage('detailDCO', 'true');
            } else {
                setLocalStorage('detailDCO', 'false');
            }
        }
    };
    useEffect(() => {
        if (docNumberValue) {
            fetchDocumentTypeInfo(Number(docNumberValue));
        } else {
            setLocalStorage('detailDCO', 'false');
        }
        return () => {
            removeFromLocalStorage('detailDCO');
        };
    }, [docNumberValue]);
    // useEffect(()=>{console.log(approvers)},[approvers]);
    const { user } = useAuthDataContext();
    let latestDocVersion = 0;
    docVersions.forEach((docVersion) => {
        const versionNumber = parseFloat(docVersion.label);
        if (versionNumber > latestDocVersion) {
            latestDocVersion = versionNumber;
        }
    });
    const [unsavedComment, setUnsavedComment] = useState('');
    const setUnsavedCommentFunction = (comment: string) => {
        setUnsavedComment(comment);
    };
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [showCheckoutConfirmationModal, setCheckoutConfirmationModal] = useState(false);
    const [activeTab, setActiveTab] = useState('Doc_Details');
    const [activeTabInHistory, setActiveTabInHistory] = useState('General');
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [SaveLoading, setSaveLoading] = useState(false);
    const [newDocNumber, setNewDocNumber] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmClicked, setConfirmClicked] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [customUserType, setCustomUserType] = useState(userType);
    const submitHandler = async () => {
        setSaveLoading(true);
        const status = await onDocumentSubmit(false);
        if (status.status === 'SUCCESS') {
            setNewDocNumber(status.docNumber);
            setModalOpen(true);
        } else if (status.errorMessage && status.errorMessage === 'Invalid Document') {
            // toast.error('Some Mandatory fields are missing. Click on Edit button and fill them.');
        }
        setSaveLoading(false);
    };
    useEffect(() => {
        if (confirmClicked) {
            submitHandler();
        }
        setConfirmClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmClicked]);

    const [confirmationModalData, setConfirmationModalData] = useState({
        type: 'EMPTY_APPROVER',
        onConfirm: () => {
            setConfirmClicked(true);
        },
    });
    useEffect(() => {
        setConfirmationModal(true);
        if (approvers.length > 0) {
            approvers.forEach((approver) => {
                if (approver.approvers.length > 0) {
                    setConfirmationModal(false);
                }
            });
        }
    }, [approvers]);
    const handleCheckout = async () => {
        setButtonStatus('LOADING');
        const res = await onDocumentCheckIn(false, activeDocVersionId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.CHECK_OUT);
            navigate(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
            setDocVersionCheckedInFlag(false);
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.CHECK_OUT);
        }
        setButtonStatus('DEFAULT');
    };
    const checkoutState = async () => {
        if (!docVersionCheckedInFlag && user.id !== checkInVersionOwner.id)
            setCheckoutConfirmationModal(true);
        else handleCheckout();
    };

    // check if logged-in user is a valid approver
    const isValidApprover = (userId: number) => {
        const approverIDsArr: number[] = [];
        approvers.forEach((approversArr) => {
            approversArr.approvers.forEach((approver) => {
                if (approver?.state === 'UNDER_REVIEW') {
                    const empId: any = approver?.employee?.value;
                    approverIDsArr.push(empId);
                }

                const adhocApprovers = approver?.adHoc || [];
                adhocApprovers.forEach((adhocApprover) => {
                    if (adhocApprover?.state === 'UNDER_REVIEW') {
                        const empId: any = adhocApprover?.employee?.value;
                        approverIDsArr.push(empId);
                    }
                });
            });
        });

        if (approverIDsArr.includes(userId)) {
            return true;
        }
        return false;
    };

    // Show Approver buttons only for valid approvers with document details link
    const updateCustomUserType = () => {
        let type = userType;
        if (
            activeVersionOwnerInfo.id !== user.id &&
            docInfo.docState === 'UNDER_REVIEW' &&
            isValidApprover(user.id)
        ) {
            type = 'APPROVER';
        } else if (
            activeVersionOwnerInfo.id !== user.id &&
            userType === 'CREATOR' &&
            !isValidApprover(user.id)
        ) {
            type = 'OTHER';
        } else if (userType !== 'CREATOR' && !isValidApprover(user.id)) {
            type = 'OTHER';
        } else {
            type = userType;
        }
        if (delegatorInfo && delegatorInfo.value !== 0 && type !== 'APPROVER') {
            setCustomUserType('APPROVER');
            return;
        }
        setCustomUserType(type);
    };

    useEffect(() => {
        updateCustomUserType();
    }, [approvers, docInfo, delegatorInfo]);

    const [fieldData] = fieldsData.filter((a) => a.name === 'LinkedDocuments');
    const [filesData] = fieldsData.filter((field) => field.name === 'file');
    return (
        <>
            {canViewDocumentDetails ? (
                <>
                    <DocumentInfo
                        pageType="DOCUMENT_DETAIL"
                        docInfo={docInfo}
                        documentId={documentId}
                        activeDocVersion={selectedDocVersion}
                        latestDocVersionId={activeDocVersionId}
                        docVersions={docVersions}
                        setActiveDocVersion={setSelectedDocVersion}
                        setDocInfo={setDocInfo}
                        onFavoriteClickHAndler={fetchDocumentVersion}
                        descError={docTypeInfo.isDCO ? docDescError : false}
                        titleError={docTypeInfo.isDCO ? docTitleError : false}
                        effectiveFromError={false}
                        effectivePeriodError={false}
                        sunsetPeriodError={false}
                        delegatorInfo={delegatorInfo}
                        docTypeInfo={docTypeInfo}
                        publishOnceApproved={publishOnceApproved}
                        isSunsetEnabled={isSunsetEnabled}
                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                        activeVersionApprovalDeadline={activeVersionApprovalDeadline}
                        setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                        checkInVersionOwner={checkInVersionOwner}
                        isEndDateAsked={isEndDateAsked}
                        relationArr={relationArr}
                        setRelationArr={setRelationArr}
                        latestrelationArr={latestrelationArr}
                        setlatestrelationArr={setlatestrelationArr}
                    />
                    <div className="document-details__section">
                        {!docTypeInfo.hideEventTimeline && (
                            <Sidebar
                                height={height}
                                documentId={documentId}
                                allowReviseByAnyone={docTypeInfo.allowReviseByAnyone || true}
                                approvers={approvers}
                                docState={docInfo.docState}
                            />
                        )}
                        {docTypeInfo.allowDCO || docTypeInfo.storeAndShowActivity ? (
                            <div
                                className={`document-details-tabs ${docTypeInfo.hideEventTimeline ? 'hideEventTimeline' : ''
                                    }`}
                            >
                                <div className="tab">
                                    <button
                                        id="Doc_Details"
                                        type="button"
                                        className={`${activeTab === 'Doc_Details' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('Doc_Details')}
                                    >
                                        <span className="name">
                                            {docTypeInfo.isDCO ? 'Affected Documents' : 'Details'}
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        id="Doc_History"
                                        className={`${activeTab === 'Doc_History' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('Doc_History')}
                                    >
                                        <span className="name">History</span>
                                    </button>
                                    {docTypeInfo.allowDCO && (
                                        <button
                                            type="button"
                                            id="Ref_Docs_Tree"
                                            className={`${activeTab === 'Ref_Docs_Tree' ? 'active' : ''
                                                }`}
                                            onClick={() => setActiveTab('Ref_Docs_Tree')}
                                        >
                                            <span className="name">Relationships</span>
                                        </button>
                                    )}
                                </div>

                                {activeTab === 'Doc_Details' && (
                                    <div
                                        className="main-section storeAndShowActivity"
                                        id="docDetailMain"
                                        ref={heightRef}
                                    >
                                        <div className="document-details">
                                            <div className="document-details-header">
                                                <h3>
                                                    {docTypeInfo.isDCO
                                                        ? 'Affected Documents'
                                                        : 'Details'}
                                                </h3>
                                                <div>
                                                    {Number(selectedDocVersion.value) <
                                                        activeDocVersionId &&
                                                        docInfo.docState === 'PUBLISHED' && (
                                                            <label>*viewing an older version</label>
                                                        )}
                                                </div>
                                            </div>
                                            {docTypeInfo.title === 'MEMO' &&
                                                docInfo.docState === 'PUBLISHED' &&
                                                docInfo.confidential &&
                                                docInfo.author.id === user.id ? (
                                                <ImportantFieldsEditable
                                                    documentId={documentId}
                                                    isDisabled
                                                    fields={fields}
                                                    fieldsData={fieldsData}
                                                    documentDispatch={documentDispatch}
                                                />
                                            ) : (
                                                <ImportantFields
                                                    documentId={documentId}
                                                    documentVersionId={
                                                        selectedDocVersion.value as number
                                                    }
                                                    isDisabled
                                                    fields={fields.filter(
                                                        (field) =>
                                                            field.uiControl.label !== 'Reference',
                                                    )}
                                                    fieldsData={fieldsData}
                                                    documentDispatch={documentDispatch}
                                                    errorFields={
                                                        docTypeInfo.isDCO ? errorFields : []
                                                    }
                                                />
                                            )}
                                        </div>
                                        {selectedDocVersion.value &&
                                            !docTypeInfo.allowFileTypeAttachment ? (
                                            <ViewAttachments
                                                activeDocVersion={
                                                    selectedDocVersion.value as number
                                                }
                                                attachmentConfig={attachmentConfig}
                                                isDocFetched
                                                allowReviseByAnyone={
                                                    docTypeInfo.allowReviseByAnyone
                                                }
                                                checklist={checklist}
                                            />
                                        ) : null}
                                        {docTypeInfo.allowFileTypeAttachment && filesData ? (
                                            <ViewLinkedFiles filesData={filesData} />
                                        ) : null}
                                        {fieldData && (
                                            <LinkedDocuments
                                                documentDispatch={documentDispatch || null}
                                                field={
                                                    fields.filter(
                                                        (f) => f.name === 'LinkedDocuments',
                                                    )[0]
                                                }
                                                isDisabled={false}
                                                value={
                                                    fieldData?.value
                                                        ? (fieldData.value as string[])
                                                        : []
                                                }
                                                error={false}
                                                isEditable={documentDispatch !== null}
                                                isChild={false}
                                            />
                                        )}
                                        <ChecklistComponent isDisabled={true} />
                                        {approvers.length > 0 &&
                                            approvers[0].approvers.length > 0 && (
                                                <h3>
                                                    {docTypeInfo.approversSectionTitle
                                                        ? docTypeInfo.approversSectionTitle
                                                        : 'Approvers'}
                                                </h3>
                                            )}
                                        {approvers.length > 0 && (
                                            <div className="approvers">
                                                <div className="approvers-tab tab-data">
                                                    {/* eslint-disable-next-line max-lines-per-function */}
                                                    {approvers.map((approver, index) => (
                                                        <>
                                                            {(approver.approvers.length > 0 || (isGroupMember && approvers[0]?.approvers[0]?.state === 'UNDER_REVIEW' && docTypeInfo.isDCO)) ? (
                                                                docTypeInfo.isContract &&
                                                                    approver.scopeType ===
                                                                    'groupBased' ? (
                                                                    <>
                                                                        <StageCard
                                                                            stageIndex={index}
                                                                            error={false}
                                                                            stageData={approver}
                                                                            approversDispatch={
                                                                                approversDispatch
                                                                            }
                                                                            setActiveVersionApprovalDeadline={
                                                                                setActiveVersionApprovalDeadline
                                                                            }
                                                                            isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                                            // eslint-disable-next-line react/no-array-index-key
                                                                            key={index}
                                                                            cardTitle={
                                                                                approver?.approvalStageTitle
                                                                                    ? approver.approvalStageTitle
                                                                                    : `Select Approvers`
                                                                            }
                                                                            docTypeInfo={
                                                                                docTypeInfo
                                                                            }
                                                                            docInfo={docInfo}
                                                                            activeVersionOwnerInfo={
                                                                                activeVersionOwnerInfo
                                                                            }
                                                                            saveFieldsData={
                                                                                saveFieldsData
                                                                            }
                                                                        />
                                                                        {externalApprovers?.length ? (
                                                                            <ExternalStageCard
                                                                                externalApprovers={
                                                                                    externalApprovers
                                                                                }
                                                                                isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                                                docTypeInfo={
                                                                                    docTypeInfo
                                                                                }
                                                                                externalApproversDispatch={
                                                                                    externalApproversDispatch
                                                                                }
                                                                                docInfo={docInfo}
                                                                            />
                                                                        ) : null}
                                                                    </>
                                                                ) : (
                                                                    <StageCard
                                                                        stageIndex={index}
                                                                        error={false}
                                                                        stageData={approver}
                                                                        approversDispatch={
                                                                            approversDispatch
                                                                        }
                                                                        setActiveVersionApprovalDeadline={
                                                                            setActiveVersionApprovalDeadline
                                                                        }
                                                                        isEditable={
                                                                            isGroupMember &&
                                                                            approvers[0]
                                                                                ?.approvers[0]
                                                                                ?.state ===
                                                                            'UNDER_REVIEW' &&
                                                                            (docTypeInfo.isDCO || (docTypeInfo.isContract && !isDocusignTriggered))
                                                                        }
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        key={index}
                                                                        cardTitle={
                                                                            approver?.approvalStageTitle
                                                                                ? approver.approvalStageTitle
                                                                                : `Select Approvers`
                                                                        }
                                                                        docTypeInfo={docTypeInfo}
                                                                        docInfo={docInfo}
                                                                        activeVersionOwnerInfo={
                                                                            activeVersionOwnerInfo
                                                                        }
                                                                        saveFieldsData={
                                                                            saveFieldsData
                                                                        }
                                                                    />
                                                                )
                                                            ) : null}
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {reviewers.length > 0 && (
                                            <div className="reviewers">
                                                <h3>Reviewers</h3>
                                                <Reviewers isEditable={false} />
                                            </div>
                                        )}
                                        {
                                            // userType !== 'OTHER' &&
                                            <div className="comments">
                                                <DocComments
                                                    activeDocVersion={
                                                        selectedDocVersion.value as number
                                                    }
                                                    setUnsavedCommentFunction={
                                                        setUnsavedCommentFunction
                                                    }
                                                    allowReviseByAnyone={
                                                        docTypeInfo.allowReviseByAnyone
                                                    }
                                                    userType={userType}
                                                    pageType="DOCUMENT_DETAIL"
                                                    approvers={approvers}
                                                />
                                            </div>
                                        }
                                        {(userType === 'CREATOR' ||
                                            userType === 'APPROVER' ||
                                            userType === 'OTHER' ||
                                            docTypeInfo.allowReviseByAnyone) &&
                                            ((docInfo.docState === 'UNDER_REVIEW' &&
                                                !docInfo.approvedOn) ||
                                                docInfo.docState === 'PUBLISHED' ||
                                                docInfo.docState === 'APPROVED' ||
                                                (docInfo.docState === 'SUNSET' &&
                                                    activeDocVersionId ===
                                                    selectedDocVersion.value)) && (
                                                <DocumentAction
                                                    docState={docInfo.activeDocumentState}
                                                    documentId={documentId}
                                                    userType={customUserType}
                                                    taskId={taskId}
                                                    activeDocumentVersion={
                                                        selectedDocVersion.value as number
                                                    }
                                                    hideUsers={hideUsers}
                                                    setHideUsers={setHideUsers}
                                                    unsavedComment={unsavedComment}
                                                    setUnsavedCommentFunction={
                                                        setUnsavedCommentFunction
                                                    }
                                                    render={docInfo.render}
                                                    assignee={docInfo.assignee}
                                                    isSunsetEnabled={isSunsetEnabled}
                                                    accessibleByCurrentUser={
                                                        accessibleByCurrentUser
                                                    }
                                                    autoRenew={docInfo.autoRenew}
                                                    docInfo={docInfo}
                                                    setDocInfo={setDocInfo}
                                                    isEndDateAsked={isEndDateAsked}
                                                    collectComment={collectComment}
                                                    collectCommentRejection={
                                                        collectCommentRejection
                                                    }
                                                    isDocusignTriggered={
                                                        approvers[0]?.approvers[0]
                                                            ?.docuSignTriggered
                                                            ? approvers[0].approvers[0]
                                                                .docuSignTriggered
                                                            : false
                                                    }
                                                />
                                            )}
                                        {docInfo.activeDocumentState === 'DRAFT' &&
                                            docTypeInfo.allowDCO &&
                                            (docVersionCheckedInFlag ||
                                                docInfo.versionOwner.id !== user.id) &&
                                            (latestrelationArr.length > 0
                                                ? latestrelationArr.some(
                                                    (obj) => obj.state !== 'UNDER_REVIEW',
                                                )
                                                : true) && (
                                                <Button
                                                    type="button"
                                                    className="primary-btn"
                                                    onClick={checkoutState}
                                                    disabled={
                                                        buttonStatus === 'LOADING' ||
                                                        docInfo.title === CONSTANTS.LOADING.TEXT
                                                    }
                                                    isLoading={buttonStatus === 'LOADING'}
                                                >
                                                    {CREATE_DOCUMENTS.BUTTON.CHECK_OUT}
                                                </Button>
                                            )}
                                        {showCheckoutConfirmationModal && (
                                            <CheckoutConfirmationModel
                                                isCheckoutModalOpen={showCheckoutConfirmationModal}
                                                onCheckoutModalClose={setCheckoutConfirmationModal}
                                                onCheckoutConfirm={handleCheckout}
                                                checkInVersionOwner={checkInVersionOwner}
                                            />
                                        )}
                                        {(docInfo.docState === 'REJECTED' ||
                                            docInfo.docState === 'DRAFT') &&
                                            !latestrelationArr.some(
                                                (obj) => obj.state === 'UNDER_REVIEW',
                                            ) &&
                                            !docVersionCheckedInFlag &&
                                            Number(selectedDocVersion.value) ===
                                            activeDocVersionId &&
                                            (docInfo.versionOwner.id === user.id ||
                                                docTypeInfo.isDCO) &&
                                            !docTypeInfo.isFileType && (
                                                <button
                                                    type="button"
                                                    className="btn primary-btn"
                                                    onClick={() =>
                                                        navigate(
                                                            `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`,
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        {docInfo.docState === 'DRAFT' &&
                                            docTypeInfo.isDCO &&
                                            (taskId === null || taskId === '') && (
                                                <Button
                                                    type="button"
                                                    className="primary-btn"
                                                    onClick={async () => {
                                                        setSubmitClicked(true);
                                                        if (!showConfirmationModal) submitHandler();
                                                    }}
                                                    disabled={
                                                        submitDocStatus === 'LOADING' || SaveLoading
                                                    }
                                                    isLoading={
                                                        submitDocStatus === 'LOADING' || SaveLoading
                                                    }
                                                    loaderColor="#f37321"
                                                >
                                                    {CREATE_DOCUMENTS.BUTTON.SUBMIT}
                                                </Button>
                                            )}
                                        {/* {docTypeInfo.allowReviseByAnyone && docInfo.docState === 'DRAFT' && docInfo.approvedVersionId === selectedDocVersion.value  && (
                                            <div className="check-out-info">
                                                NOTE: This document has been checked out by: {activeVersionOwnerInfo.employeeName} <a href={`mailto:${activeVersionOwnerInfo.email}`}>{activeVersionOwnerInfo.email}</a>
                                            </div>
                                        )} */}
                                        <Modal
                                            isOpen={modalOpen}
                                            contentLabel="submit Modal"
                                            className="doc_submit__modal"
                                        >
                                            <div className="modal-body">
                                                <div className="icon">
                                                    <SuccessfulIcon />
                                                </div>
                                                {docTypeInfo.isDCO ? (
                                                    <h1>DCO Submitted</h1>
                                                ) : (
                                                    <h1>Document Submitted</h1>
                                                )}
                                                {newDocNumber &&
                                                    (docTypeInfo.isDCO ? (
                                                        <h4>
                                                            Change Number : <i>{newDocNumber}</i>
                                                        </h4>
                                                    ) : (
                                                        <h4>
                                                            Document Number : <i>{newDocNumber}</i>
                                                        </h4>
                                                    ))}
                                                <p>
                                                    On an average, it takes about 2 days for
                                                    approvals
                                                </p>
                                                <button
                                                    className="primary-btn"
                                                    type="button"
                                                    onClick={() => {
                                                        navigate(
                                                            `document-details?doc_id=${documentId}`,
                                                        );
                                                        window.location.reload();
                                                    }}
                                                >
                                                    {CREATE_DOCUMENTS.BUTTON.VIEW_SUBMISSION}
                                                </button>
                                            </div>
                                        </Modal>
                                        {submitClicked && showConfirmationModal && (
                                            <ConfirmationModal
                                                type={confirmationModalData.type as IAcceptedType}
                                                isModalOpen={showConfirmationModal}
                                                onModalClose={setSubmitClicked}
                                                onConfirm={confirmationModalData.onConfirm}
                                            />
                                        )}
                                    </div>
                                )}

                                {activeTab === 'Doc_History' && (
                                    <div className="history-tab">
                                        <div className="doc-history">
                                            <div className="doc-history-tab">
                                                <button
                                                    type="button"
                                                    id="General"
                                                    className={`${activeTabInHistory === 'General'
                                                        ? 'active'
                                                        : ''
                                                        }`}
                                                    onClick={() => setActiveTabInHistory('General')}
                                                >
                                                    <span className="name">General</span>
                                                </button>
                                                {docTypeInfo.allowDCO && (
                                                    <button
                                                        type="button"
                                                        id="Revisions"
                                                        className={`${activeTabInHistory === 'Revisions'
                                                            ? 'active'
                                                            : ''
                                                            }`}
                                                        onClick={() =>
                                                            setActiveTabInHistory('Revisions')
                                                        }
                                                    >
                                                        <span className="name">Revisions</span>
                                                    </button>
                                                )}
                                            </div>
                                            {activeTabInHistory === 'General' && (
                                                <ActivitySection
                                                    documentId={documentId}
                                                    docTypeInfo={docTypeInfo}
                                                />
                                            )}
                                            {docTypeInfo.allowDCO &&
                                                activeTabInHistory === 'Revisions' && (
                                                    <DocumentHistory documentId={documentId} />
                                                )}
                                        </div>
                                    </div>
                                )}

                                {docTypeInfo.allowDCO && activeTab === 'Ref_Docs_Tree' && (
                                    <div className="doc-history">
                                        <RefDocsTreeNodes activeDocVersionId={activeDocVersionId} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="main-section" id="docDetailMain" ref={heightRef}>
                                <div className="document-details">
                                    <div className="document-details-header">
                                        <h3>
                                            {docTypeInfo.isDCO ? 'Affected Documents' : 'Details'}
                                        </h3>
                                        <div>
                                            {Number(selectedDocVersion.value) <
                                                activeDocVersionId &&
                                                docInfo.docState === 'PUBLISHED' && (
                                                    <label>*viewing an older version</label>
                                                )}
                                        </div>
                                    </div>
                                    {docTypeInfo.title === 'MEMO' &&
                                        docInfo.docState === 'PUBLISHED' &&
                                        docInfo.confidential &&
                                        docInfo.author.id === user.id ? (
                                        <ImportantFieldsEditable
                                            documentId={documentId}
                                            isDisabled
                                            fields={fields}
                                            fieldsData={fieldsData}
                                            documentDispatch={documentDispatch}
                                        />
                                    ) : (
                                        <ImportantFields
                                            documentId={documentId}
                                            documentVersionId={selectedDocVersion.value as number}
                                            isDisabled
                                            fields={fields}
                                            fieldsData={fieldsData}
                                            documentDispatch={documentDispatch}
                                            errorFields={docTypeInfo.isDCO ? errorFields : []}
                                        />
                                    )}
                                </div>
                                {docTypeInfo.allowFileTypeAttachment && filesData ? (
                                    <ViewLinkedFiles filesData={filesData} />
                                ) : null}
                                {selectedDocVersion.value &&
                                    !docTypeInfo.allowFileTypeAttachment ? (
                                    <ViewAttachments
                                        activeDocVersion={selectedDocVersion.value as number}
                                        attachmentConfig={attachmentConfig}
                                        isDocFetched
                                        allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                        checklist={checklist}
                                    />
                                ) : null}
                                <ChecklistComponent isDisabled={true} />
                                {((approvers.length > 0 && approvers[0].approvers.length > 0) ||
                                    externalApprovers.length > 0) && (
                                        <h3>
                                            {docTypeInfo.approversSectionTitle
                                                ? docTypeInfo.approversSectionTitle
                                                : 'Approvers'}
                                        </h3>
                                    )}
                                {approvers.length > 0 && (
                                    <div className="approvers">
                                        <div className="approvers-tab tab-data">
                                            {/* eslint-disable-next-line max-lines-per-function */}
                                            {approvers.map((approver, index) => (
                                                <>
                                                    {approver.approvers.length > 0 ? (
                                                        docTypeInfo.isContract &&
                                                            approver.scopeType === 'groupBased' ? (
                                                            <>
                                                                <StageCard
                                                                    stageIndex={index}
                                                                    error={false}
                                                                    stageData={approver}
                                                                    approversDispatch={
                                                                        approversDispatch
                                                                    }
                                                                    setActiveVersionApprovalDeadline={
                                                                        setActiveVersionApprovalDeadline
                                                                    }
                                                                    isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)
                                                                    }
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    key={index}
                                                                    cardTitle={
                                                                        approver?.approvalStageTitle
                                                                            ? approver.approvalStageTitle
                                                                            : `Select Approvers`
                                                                    }
                                                                    docTypeInfo={docTypeInfo}
                                                                    docInfo={docInfo}
                                                                    activeVersionOwnerInfo={
                                                                        activeVersionOwnerInfo
                                                                    }
                                                                    saveFieldsData={saveFieldsData}
                                                                />
                                                                {externalApprovers?.length ? (
                                                                    <ExternalStageCard
                                                                        externalApprovers={
                                                                            externalApprovers
                                                                        }
                                                                        isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered)}
                                                                        docTypeInfo={docTypeInfo}
                                                                        externalApproversDispatch={
                                                                            externalApproversDispatch
                                                                        }
                                                                        docInfo={docInfo}
                                                                    />
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <StageCard
                                                                stageIndex={index}
                                                                error={false}
                                                                stageData={approver}
                                                                approversDispatch={
                                                                    approversDispatch
                                                                }
                                                                setActiveVersionApprovalDeadline={
                                                                    setActiveVersionApprovalDeadline
                                                                }
                                                                isEditable={
                                                                    isGroupMember &&
                                                                    approvers[0]?.approvers[0]
                                                                        ?.state ===
                                                                    'UNDER_REVIEW' &&
                                                                    (docTypeInfo.isDCO || (docTypeInfo.isContract && !isDocusignTriggered))
                                                                }
                                                                // eslint-disable-next-line react/no-array-index-key
                                                                key={index}
                                                                cardTitle={
                                                                    approver?.approvalStageTitle
                                                                        ? approver.approvalStageTitle
                                                                        : `Select Approvers`
                                                                }
                                                                docTypeInfo={docTypeInfo}
                                                                docInfo={docInfo}
                                                                activeVersionOwnerInfo={
                                                                    activeVersionOwnerInfo
                                                                }
                                                                saveFieldsData={saveFieldsData}
                                                            />
                                                        )
                                                    ) : null}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {reviewers.length > 0 && (
                                    <div className="reviewers">
                                        <h3>Reviewers</h3>
                                        <Reviewers isEditable={false} />
                                    </div>
                                )}
                                {
                                    // userType !== 'OTHER' &&
                                    <div className="comments">
                                        <DocComments
                                            activeDocVersion={selectedDocVersion.value as number}
                                            setUnsavedCommentFunction={setUnsavedCommentFunction}
                                            allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                            userType={userType}
                                            pageType="DOCUMENT_DETAIL"
                                            approvers={approvers}
                                        />
                                    </div>
                                }
                                {(userType === 'CREATOR' ||
                                    userType === 'APPROVER' ||
                                    userType === 'OTHER' ||
                                    docTypeInfo.allowReviseByAnyone) &&
                                    ((docInfo.docState === 'UNDER_REVIEW' && !docInfo.approvedOn) ||
                                        docInfo.docState === 'PUBLISHED' ||
                                        docInfo.docState === 'APPROVED' ||
                                        (docInfo.docState === 'SUNSET' &&
                                            activeDocVersionId === selectedDocVersion.value)) && (
                                        <DocumentAction
                                            docState={docInfo.activeDocumentState}
                                            documentId={documentId}
                                            userType={customUserType}
                                            taskId={taskId}
                                            activeDocumentVersion={
                                                selectedDocVersion.value as number
                                            }
                                            hideUsers={hideUsers}
                                            setHideUsers={setHideUsers}
                                            unsavedComment={unsavedComment}
                                            setUnsavedCommentFunction={setUnsavedCommentFunction}
                                            render={docInfo.render}
                                            assignee={docInfo.assignee}
                                            isSunsetEnabled={isSunsetEnabled}
                                            accessibleByCurrentUser={accessibleByCurrentUser}
                                            autoRenew={docInfo.autoRenew}
                                            docInfo={docInfo}
                                            setDocInfo={setDocInfo}
                                            isEndDateAsked={isEndDateAsked}
                                            isDocusignTriggered={
                                                approvers[0]?.approvers[0]?.docuSignTriggered
                                                    ? approvers[0].approvers[0].docuSignTriggered
                                                    : false
                                            }
                                            collectComment={collectComment}
                                            collectCommentRejection={collectCommentRejection}
                                        />
                                    )}
                                {/* {docTypeInfo.allowReviseByAnyone && docInfo.docState === 'DRAFT' && docInfo.approvedVersionId === selectedDocVersion.value  && (
                                <div className="check-out-info">
                                    NOTE: This document has been checked out by: {activeVersionOwnerInfo.employeeName} <a href={`mailto:${activeVersionOwnerInfo.email}`}>{activeVersionOwnerInfo.email}</a>
                                </div>
                            )} */}
                            </div>
                        )}
                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const DocumentDetailsPage: React.FC = () => (
    <div className="document-details">
        <DocumentProvider>
            <EventsHeightProvider>
                <DocumentDetails />
            </EventsHeightProvider>
        </DocumentProvider>
    </div>
);

export default DocumentDetailsPage;
