/* eslint-disable no-debugger */
/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react'
import { TagsInput } from "react-tag-input-component";
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { getDbLookUps } from '../../API/fields';
import { DragIconSvg, DeleteSvg } from '../../assets/images';
import Toggle from '../../components/toggle/Toggle';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import { IDataTypeMapping, IDocTypeFieldsOptionValue, IField, IUiControl } from '../../interfaces/document';
import { IOptionValue } from '../../interfaces';
import { IDbLookUps } from '../../interfaces/field';
import './DocTypeField.scss';
import { ICardError, IDocTypeCard } from '../../interfaces/document-type';
import { getAllDocumentTypes, getDocTypeVersion } from '../../API/document-type';

interface Props {
    fieldData: IField;
    index: number;
    dataTypeMapping: IDataTypeMapping[];
    isDisabled: boolean;
    errors: ICardError;
}

const DocTypeField: React.FC<Props> = ({
    fieldData,
    index,
    dataTypeMapping,
    isDisabled,
    errors,
}: Props) => {
    const { docTypeDispatch, docTypeInfo, documentTypeId } = useDocTypeContext();
    const [data, setData] = useState<IDbLookUps[]>([]);
    const [tableColumns, setTableColumns] = useState<IOptionValue[]>([]);
    const [step, setStep] = useState<number>(0.01);
    const [maxLength, setMaxLength] = useState(fieldData.maxLength);

    useEffect(() => {
        const a = async () => {
            if (fieldData.selectType === 'dynamic') {
                const data1 = await getDbLookUps();
                if (data1?.apiStatus === 'SUCCESS') {
                    setData(data1.dbLookUps);
                    const tableName = fieldData.dynamicOptionValues?.tableName;
                    let tableColumn = data1.dbLookUps[0];
                    if (tableName) {
                        // eslint-disable-next-line prefer-destructuring
                        tableColumn = data1.dbLookUps.filter((db) => db.label === tableName)[0];
                    }
                    if (tableColumn) {
                        setTableColumns(tableColumn.columns);
                    }
                }
            }
        };
        a();
    }, [fieldData.dynamicOptionValues?.tableName, fieldData.selectType]);

    const changeTableColumns = (name: string) => {
        const [value] = data.filter((a) => a.label === name);
        setTableColumns(value.columns);
    };

    interface IDocTypeDetailsAndFields {
        details: IDocTypeCard;
        fields: IOptionValue[]
    };
    const [docTypes, setDocTypes] = useState<IOptionValue[]>([]);
    const [docTypeDetails, setDocTypeDetails] = useState<IDocTypeCard[]>([]);
    const [docTypeDetailsAndFields, setDocTypeDetailsAndFields] = useState<IDocTypeDetailsAndFields[]>([]);

    const fetchDocTypes = async () => {
        const res = await getAllDocumentTypes();
        if (res?.apiStatus === 'SUCCESS') {
            const allowedDocTypeCards = res.docTypeCard.filter(currDocType => currDocType.config.allowDCO && currDocType.id !== documentTypeId);
            setDocTypeDetails(allowedDocTypeCards);
            const docTypeOptionValues: IOptionValue[] = allowedDocTypeCards.map(
                (r: { name: string; id: number }) => ({
                    label: r.name,
                    value: r.id,
                }),
            );
            setDocTypes(docTypeOptionValues);


        }
    }

    const fetchDocTypeVersion = async (docTypeId: number, docTypeVersionId: number) => {
        const res = await getDocTypeVersion(docTypeId, docTypeVersionId);
        if (res) {
            const docTypeFieldsData: IOptionValue[] = res.fields.map(
                (r: { name: string; id: number }) => ({
                    label: r.name,
                    value: r.id,
                }),
            );
            // setDocTypeFields(docTypeFieldsData);
            return docTypeFieldsData;
        }
        return [];
    }
    useEffect(() => {
        if (fieldData.dataType.label === 'document')
            fetchDocTypes();

    }, [fieldData.dataType.label])
    useEffect(() => {
        const fetchData = async () => {
            if (fieldData.docTypeOptionValues && fieldData.docTypeOptionValues.length > 0) {
                const docTypeNames = fieldData.docTypeOptionValues.map(option => option.label);
                const newDocTypeDetailsAndFieldsPromises = docTypeNames.map(async docTypeName => {
                    const currDocTypeDetails = docTypeDetails.find(docType => docType.name === docTypeName);
                    if (currDocTypeDetails) {
                        try {
                            const fields = await fetchDocTypeVersion(currDocTypeDetails.id, currDocTypeDetails.activeVersion);
                            return {
                                details: currDocTypeDetails,
                                fields
                            };
                        }
                        catch (error) {
                            return null;
                        }
                    }
                    return null;
                });

                const newDocTypeDetailsAndFields = (await Promise.all(newDocTypeDetailsAndFieldsPromises)).filter(Boolean);
                setDocTypeDetailsAndFields(newDocTypeDetailsAndFields as IDocTypeDetailsAndFields[]);
            }
        }

        fetchData();
    }, [fieldData.dataType.label, docTypeDetails]);


    return (
        <div className="data-field-type">
            <div className="form-section">
                <div>
                    <label htmlFor="field-title">Field Display Name</label>
                    <input
                        type="text"
                        className={`${errors.fieldList && errors.fieldList.includes('displayName')
                            ? 'error'
                            : ''
                            }`}
                        name="field-display-name"
                        id="field-display-name"
                        autoComplete="off"
                        value={fieldData.displayName}
                        onChange={(e) =>
                            docTypeDispatch({
                                type: 'field-display-name',
                                key: index,
                                value: e.target.value,
                                changeInternalName:
                                    docTypeInfo.version === '1.0' || docTypeInfo.version === '',
                            })
                        }
                        disabled={isDisabled}
                    />
                </div>
                <div>
                    <label htmlFor="field-title">Internal Field Name</label>
                    <input
                        type="text"
                        className={`${errors.fieldList && errors.fieldList.includes('name') ? 'error' : ''
                            }`}
                        name="field-name"
                        id="field-name"
                        autoComplete="off"
                        value={fieldData.name}
                        onChange={(e) =>
                            docTypeDispatch({
                                type: 'field-name',
                                key: index,
                                value: e.target.value,
                            })
                        }
                        disabled={isDisabled}
                    />
                </div>
                <div>
                    <label htmlFor="helper-text">Help Text</label>
                    <input
                        type="text"
                        className={`${errors.fieldList && errors.fieldList.includes('helper-text')
                            ? 'error'
                            : ''
                            }`}
                        name="helper-text"
                        id="helper-text"
                        autoComplete="off"
                        value={fieldData.helperText}
                        onChange={(e) =>
                            docTypeDispatch({
                                type: 'helper-text',
                                key: index,
                                value: e.target.value,
                            })
                        }
                        disabled={isDisabled}
                    />
                </div>
                <div>
                    <label htmlFor="data-type">Data Type</label>
                    <Select
                        name={`${fieldData.id}`}
                        id={`${fieldData.id}`}
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={dataTypeMapping}
                        value={fieldData.dataType as IDataTypeMapping}
                        onChange={(option) => {
                            if (option) {
                                docTypeDispatch({
                                    type: 'data-type',
                                    key: index,
                                    value: option,
                                });

                                if (option.label === 'document') {
                                    fetchDocTypes();
                                }
                            }
                        }}
                        isDisabled={isDisabled}
                    />
                    <div className="select-handler">
                        <h4 >ReadOnly Field</h4>
                        <Toggle
                            isChecked={fieldData.readOnly}
                            isDisabled={isDisabled}
                            onclick={() =>
                                docTypeDispatch({
                                    type: 'readOnly',
                                    key: index,
                                    value: !fieldData.readOnly,
                                })
                            }
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="ui-type">UI Type</label>
                    <Select
                        name={`${fieldData.id}`}
                        id={`${fieldData.id}`}
                        isMulti={false}
                        className="react-select"
                        classNamePrefix="select"
                        options={fieldData.dataType.uiControls}
                        value={fieldData.uiControl as IUiControl}
                        onChange={(option) => {
                            setMaxLength(0);
                            if (option) {
                                docTypeDispatch({
                                    type: 'ui-type',
                                    key: index,
                                    value: option,
                                });
                            }
                        }}
                        isDisabled={isDisabled}
                    />
                    {(fieldData.uiControl.label === 'Single Select Dropdown' ||
                        fieldData.uiControl.label === 'Multi Select Dropdown') && (
                            <div className="select-handler">
                                <input
                                    type="radio"
                                    id={`static-${fieldData.id.toString()}`}
                                    name={`static-${fieldData.id.toString()}`}
                                    value="static"
                                    checked={fieldData.selectType === 'static'}
                                    onClick={() =>
                                        docTypeDispatch({
                                            type: 'select-type',
                                            key: index,
                                            value: 'static',
                                        })
                                    }
                                    disabled={isDisabled}
                                />
                                <label htmlFor={`static-${fieldData.id.toString()}`}>Static</label>
                                <input
                                    type="radio"
                                    id={`dynamic-${fieldData.id.toString()}`}
                                    name={`dynamic-${fieldData.id.toString()}`}
                                    value="dynamic"
                                    checked={fieldData.selectType === 'dynamic'}
                                    onClick={() =>
                                        docTypeDispatch({
                                            type: 'select-type',
                                            key: index,
                                            value: 'dynamic',
                                        })
                                    }
                                    disabled={isDisabled}
                                />
                                <label htmlFor={`dynamic-${fieldData.id.toString()}`}>Dynamic</label>
                            </div>
                        )}
                </div>
                {(fieldData.uiControl.label === 'Float') && (<div className='step-for-float'>
                    <label htmlFor={`step-for-float-${fieldData.id}`} >Step</label>
                    <input type="number"
                        step="any"
                        id={`step-for-float-${fieldData.id}`}
                        name={`step-for-float-${fieldData.id}`}
                        value={step}
                        onChange={(e) => {
                            const stepValue = e.target.value !== "" ? Math.max(parseFloat(e.target.value), 0) : 0.01;
                            setStep(stepValue);
                            docTypeDispatch({
                                type: "step",
                                key: index,
                                value: stepValue,
                            })
                        }
                        }
                    />
                </div>)}
                {(fieldData.uiControl.label === "Text Box" || fieldData.uiControl.label === "Text") && (
                    <div className='maximum-limit-text-area'>
                        <label htmlFor={`maximum-length-${fieldData.id}`} >Maximum Character Count</label>
                        <input type="number"
                            id={`maximum-length-${fieldData.id}`}
                            name={`maximum-length-${fieldData.id}`}
                            value={maxLength}
                            onChange={(e) => {
                                let updatedValue = e.target.value !== "" ? parseInt(e.target.value, 10) : 0;
                                updatedValue = updatedValue < 0 ? 0 : updatedValue;
                                setMaxLength(updatedValue);
                                docTypeDispatch({
                                    type: "field-max-length",
                                    key: index,
                                    value: updatedValue,
                                })
                            }

                            }
                            disabled={isDisabled}


                        />
                    </div>

                )}
                {fieldData.selectType === 'static' && fieldData.uiControl.label !== 'Markup' && (
                    <div>
                        <label htmlFor="tags-input">Option Values</label>
                        <div
                            className={`${errors.fieldList && errors.fieldList.includes('static-option')
                                ? 'error'
                                : ''
                                }`}
                        >
                            <TagsInput
                                value={fieldData?.staticOptionValues?.data || []}
                                onChange={(tags) =>
                                    docTypeDispatch({
                                        type: 'static-option-value',
                                        key: index,
                                        value: tags,
                                    })
                                }
                            />
                        </div>
                    </div>
                )}
                {fieldData.uiControl.label === 'Markup' && (
                    <div>
                        <label htmlFor="markup-text">Markup Text</label>
                        <ReactQuill
                            id="markup-text"
                            className={`${errors.fieldList && errors.fieldList.includes('markup-text')
                                ? 'error'
                                : ''
                                }`}
                            readOnly={isDisabled}
                            value={fieldData.markupText}
                            onChange={(input) => {
                                docTypeDispatch({
                                    type: 'markup-text',
                                    key: index,
                                    value: input,
                                });
                            }}
                        />
                    </div>
                )}

                {fieldData.uiControl.label === 'Table' && (
                    <div>
                        <label htmlFor={`table-${fieldData.id.toString()}`}>Allowed Document Types</label>
                        <Select
                            name={`table-${fieldData.id.toString()}`}
                            id={`table-${fieldData.id.toString()}`}
                            isMulti
                            className={`react-select ${errors.fieldList && errors.fieldList.includes('tableName')
                                ? 'error'
                                : ''
                                }`}
                            classNamePrefix="select"
                            options={docTypes}
                            value={fieldData.docTypeOptionValues as IOptionValue[]}
                            onChange={async (option) => {
                                if (option) {
                                    docTypeDispatch({
                                        type: 'doc-type-option-values',
                                        key: index,
                                        value: option as IOptionValue[]
                                    });

                                    // fetch fields details here
                                    const optionsLen = option.length;
                                    if (optionsLen > 0) {
                                        const docTypeName = option[optionsLen - 1].label;
                                        const currDocTypeDetails = docTypeDetails.filter(currDocType => currDocType.name === docTypeName);
                                        const checkIfExists = docTypeDetailsAndFields.filter(obj => obj.details.name === docTypeName);

                                        if (checkIfExists.length === 0 && currDocTypeDetails.length > 0) {
                                            const fields = await fetchDocTypeVersion(currDocTypeDetails[0].id, currDocTypeDetails[0].activeVersion);
                                            const newDocTypeDetailsAndFields = [{
                                                details: currDocTypeDetails[0],
                                                fields
                                            }];
                                            setDocTypeDetailsAndFields([...docTypeDetailsAndFields, ...newDocTypeDetailsAndFields])
                                        }
                                    }
                                }
                            }}
                            isDisabled={isDisabled}
                        />
                    </div>
                )}
                {
                    fieldData.docTypeOptionValues && fieldData.docTypeOptionValues.length > 0 &&
                    fieldData.docTypeOptionValues.map((docType) => {
                        const currDocTypeDetailsAndFields = docTypeDetailsAndFields.filter(obj => obj.details.name === docType.label);
                        const fieldsOptions = currDocTypeDetailsAndFields[0]?.fields;
                        const val = fieldData.docTypeFieldsOptionValues.filter(obj => obj.docTypeName === docType.label);
                        return (
                            <div key={docType.value}>
                                <label htmlFor={`fields-${fieldData.id.toString()}`}>{docType.label} Fields</label>
                                <Select
                                    name={`fields-${fieldData.id.toString()}`}
                                    id={`fields-${fieldData.id.toString()}`}
                                    isMulti
                                    className={`react-select ${errors.fieldList && errors.fieldList.includes('tableName')
                                        ? 'error'
                                        : ''
                                        }`}
                                    classNamePrefix="select"
                                    options={fieldsOptions}
                                    value={val[0]?.fields}
                                    onChange={(option) => {
                                        if (option) {
                                            docTypeDispatch({
                                                type: 'doc-type-fields-option-values',
                                                key: index,
                                                value: [{ docTypeName: docType.label, fields: option }] as IDocTypeFieldsOptionValue[]
                                            });
                                        }
                                    }}
                                    isDisabled={isDisabled}
                                />
                            </div>
                        )
                    })
                }

                {fieldData.selectType === 'dynamic' && (
                    <>
                        <div>
                            <label htmlFor={`table-${fieldData.id.toString()}`}>Table Name</label>
                            <Select
                                name={`table-${fieldData.id.toString()}`}
                                id={`table-${fieldData.id.toString()}`}
                                isMulti={false}
                                className={`react-select ${errors.fieldList && errors.fieldList.includes('tableName')
                                    ? 'error'
                                    : ''
                                    }`}
                                classNamePrefix="select"
                                options={data}
                                value={
                                    {
                                        value: fieldData.dynamicOptionValues?.tableName,
                                        label: fieldData.dynamicOptionValues?.tableName,
                                    } as IOptionValue
                                }
                                onChange={(option) => {
                                    if (option) {
                                        docTypeDispatch({
                                            type: 'dynamic-option-value',
                                            key: index,
                                            tableName: option.label as string,
                                        });
                                        changeTableColumns(option.label as string);
                                    }
                                }}
                                isDisabled={isDisabled}
                            />
                        </div>
                        {tableColumns?.length > 0 && (
                            <>
                                <div>
                                    <label htmlFor={`table-${fieldData.id.toString()}`}>
                                        Display Column
                                    </label>
                                    <Select
                                        name={`display-column-${fieldData.id.toString()}`}
                                        id={`display-column-${fieldData.id.toString()}`}
                                        isMulti={false}
                                        className={`react-select ${errors.fieldList &&
                                            errors.fieldList.includes('displayColumn')
                                            ? 'error'
                                            : ''
                                            }`}
                                        classNamePrefix="select"
                                        options={tableColumns}
                                        value={
                                            {
                                                value: fieldData.dynamicOptionValues?.displayColumn,
                                                label: fieldData.dynamicOptionValues?.displayColumn,
                                            } as IOptionValue
                                        }
                                        onChange={(option) => {
                                            if (option) {
                                                docTypeDispatch({
                                                    type: 'dynamic-option-value',
                                                    key: index,
                                                    displayColumn: option.value as string,
                                                });
                                            }
                                        }}
                                        isDisabled={isDisabled}
                                    />
                                </div>
                                <div>
                                    <label htmlFor={`value-column-${fieldData.id.toString()}`}>
                                        Value Column
                                    </label>
                                    <Select
                                        name={`value-column-${fieldData.id.toString()}`}
                                        id={`value-column-${fieldData.id.toString()}`}
                                        isMulti={false}
                                        className={`react-select ${errors.fieldList &&
                                            errors.fieldList.includes('valueColumn')
                                            ? 'error'
                                            : ''
                                            }`}
                                        classNamePrefix="select"
                                        options={tableColumns}
                                        value={
                                            {
                                                value: fieldData.dynamicOptionValues?.valueColumn,
                                                label: fieldData.dynamicOptionValues?.valueColumn,
                                            } as IOptionValue
                                        }
                                        onChange={(option) => {
                                            if (option) {
                                                docTypeDispatch({
                                                    type: 'dynamic-option-value',
                                                    key: index,
                                                    valueColumn: option.value as string,
                                                });
                                            }
                                        }}
                                        isDisabled={isDisabled}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            <div className="action-buttons">
                <span className="line" />
                <div>
                    <h5>Mandatory</h5>
                    <Toggle
                        isChecked={fieldData.mandatory}
                        isDisabled={isDisabled}
                        onclick={() =>
                            docTypeDispatch({
                                type: 'mandatory',
                                key: index,
                                value: !fieldData.mandatory,
                            })
                        }
                    />
                </div>
                <div
                    className="delete"
                    onClick={() => !isDisabled && docTypeDispatch({ type: 'delete', key: index })}
                    role="presentation"
                >
                    <h5>Delete</h5>
                    <DeleteSvg />
                </div>
                <DragIconSvg />
            </div>
        </div >
    );
};

export default DocTypeField;