import React, { useState } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';

import './DocumentAction.scss';
import { documentActionAddApprover } from '../../API/document';
import { IUserOptionValue,IButtonStatus } from '../../interfaces';
import { userSearch } from '../../API/users';
import { PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';

const animatedComponents = makeAnimated();

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    documentId: number;
    hideUsers: number[];
    taskId: string;
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
}

const { ADD_APPROVER } = STATIC_CONTENT;
// eslint-disable-next-line max-lines-per-function
const AddApproverModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    documentId,
    hideUsers,
    setHideUsers,
    taskId,
}) => {
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [isLoading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUserOptionValue[]>();
    const [error, setError] = useState(false);

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users.filter(
                        (u) => !hideUsers.includes(u.value as number),
                    );
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const onSave = async () => {
        if (selectedUser) {
            setButtonStatus('LOADING');
            const users = selectedUser.map((s) => s.value as string);
            const res = await documentActionAddApprover(documentId, users, taskId);
            if (res?.apiStatus === 'SUCCESS') {
                setHideUsers([...hideUsers, ...selectedUser.map((s) => s.value as number)]);
                toast.success(`${selectedUser.length} user(s) added as Approver(s)`);
                onModalClose(false);
            } else {
                toast.error(`Something went wrong`);
            }
            setButtonStatus('DEFAULT');
        } else {
            setError(true);
        }
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            contentLabel="Example Modal"
            className="forward_doc_modal"
        >
            <div>
                <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
                <div className="search-wrapper">
                    <label htmlFor="user-search">Select User</label>
                    <span className="note">Length should be greater than 3 characters</span>
                    <AsyncSelect
                        name="user-search"
                        id="user-search"
                        components={animatedComponents}
                        isLoading={isLoading}
                        isMulti
                        placeholder="Search user..."
                        className={`react-select user-search ${error ? 'error' : ''}`}
                        classNamePrefix="select"
                        loadOptions={promiseOptions}
                        getOptionLabel={(e: any) => `${e.label} | ${e.jobTitle}`}
                        onChange={(option) => {
                            setSelectedUser(option as IUserOptionValue[]);
                            setError(false);
                        }}
                    />
                </div>
                <div className="button-grp">
                    <button type="submit"  disabled={buttonStatus === 'LOADING'} className="primary-btn" onClick={() => onSave()}>
                        {ADD_APPROVER.BUTTON.SAVE}
                    </button>
                    <button type="submit" onClick={() => onModalClose(false)}>
                        {ADD_APPROVER.BUTTON.CANCEL}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default AddApproverModal;
