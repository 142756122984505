import React from 'react';
import { Column, Row } from 'react-table';
import { Link } from 'react-router-dom';

import { Tooltip } from 'react-tooltip';
import {
    IDashboardTabs,
    IGroupApproval,
    ILatestDocuments,
    IMyApproval,
    IMyDocument,
    IMyFavorites,
    IMyReviews,
} from '../../interfaces/dashboard';
import { DocumentIconSvg, StarIconSvg } from '../../assets/images';
import { DateColumn, StatusColumn } from '../../components/General';
import { removeFromFavorites, addToFavorites } from '../../API/dashboard';
import { IDocStatus } from '../../interfaces';
import CONSTANTS from '../../constants';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { SkeletonText } from '../../components/Skeleton';

const DATE_MIN_WIDTH = 150;

const DocNumberDocument = (row: Row<IMyDocument>) => {
    const {
        original: { docNumber, status, docId, versionOwnerId },
    } = row;
    const { user } = useAuthDataContext();
    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname:
                    status === 'DRAFT' && (user.id === versionOwnerId)
                        ? `/${CONSTANTS.RELATIVE_PATHS.createDocument.Url}`
                        : `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                search: `?doc_id=${docId}`,
            }}
        >
            <SkeletonText text={docNumber} width={200} />
        </Link>
    );
};

const DocNumberColumn = (row: Row<IMyApproval>) => {
    const { user } = useAuthDataContext();
    const {
        original: { docNumber, taskId, assigneeId, activeDelegators },
    } = row;

    const [activeDelegator] = activeDelegators.filter((a) => a.value === assigneeId);

    return (
        <div className="doc-number-column">
            {user.id !== assigneeId && activeDelegator && (
                <span className="delegated">
                    Delegated <span className="hidden"> from {activeDelegator.label}</span>
                </span>
            )}
            <Link
                className="link-style"
                style={{ textDecoration: 'none' }}
                to={{
                    pathname: `/${CONSTANTS.RELATIVE_PATHS.taskDetails.Url}`,
                    search: `?task_id=${taskId}`,
                }}
            >
                <SkeletonText text={docNumber} width={200} />
            </Link>
        </div>
    );
};

const DocNumberGroupColumn = (row: Row<IGroupApproval>) => {
    const {
        original: {
            docNumber,
            taskId,
            // assigneeId,
            // activeDelegators
        },
    } = row;

    // const [activeDelegator] = activeDelegators.filter((a) => a.value === assigneeId);

    return (
        <div className="doc-number-column">
            {/* {user.id !== assigneeId && activeDelegator && (
                <span className="delegated">
                    Delegated <span className="hidden"> from {activeDelegator.label}</span>
                </span>
            )} */}
            <Link
                className="link-style"
                style={{ textDecoration: 'none' }}
                to={{
                    pathname: `/${CONSTANTS.RELATIVE_PATHS.taskDetails.Url}`,
                    search: `?task_id=${taskId}&prev_page_type=MY_GROUP_APPROVALS`,
                }}
            >
                <SkeletonText text={docNumber} width={200} />
            </Link>
        </div>
    );
};

const DocNumberForReviews = (row: Row<IMyReviews>) => {
    const { user } = useAuthDataContext();
    const {
        original: { docNumber, taskId, assigneeId, activeDelegators },
    } = row;

    const [activeDelegator] = activeDelegators.filter((a) => a.value === assigneeId);

    return (
        <div className="doc-number-column">
            {user.id !== assigneeId && activeDelegator && (
                <span className="delegated">
                    Delegated <span className="hidden"> from {activeDelegator.label}</span>
                </span>
            )}
            <Link
                className="link-style"
                style={{ textDecoration: 'none' }}
                to={{
                    pathname: `/${CONSTANTS.RELATIVE_PATHS.taskDetails.Url}`,
                    search: `?task_id=${taskId}&prev_page_type=MY_REVIEWS`,
                }}
            >
                <SkeletonText text={docNumber} width={200} />
            </Link>
        </div>
    );
};

const DocNumberForFavorite = (row: Row<IMyFavorites>) => {
    const {
        original: { docNumber, docId },
    } = row;

    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                search: `?doc_id=${docId}&prev_page_type=MY_FAVORITES`,
            }}
        >
            <SkeletonText text={docNumber} width={200} />
        </Link>
    );
};

const DocNumberForLatest = (row: Row<ILatestDocuments>) => {
    const {
        original: { docNumber, docId },
    } = row;

    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                search: `?doc_id=${docId}&prev_page_type=MY_LATEST`,
            }}
        >
            <SkeletonText text={docNumber} width={200} />
        </Link>
    );
};
const FilesColumn = ({ value }: { value: number }) => (
    <div className="files-column">
        {value ? (
            <>
                {value} <DocumentIconSvg />{' '}
            </>
        ) : (
            <>-</>
        )}
    </div>
);

const TitleMyDocumentColumn = (row: Row<IMyDocument>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {title && isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

const TitleMyApprovalColumn = (row: Row<IMyApproval>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

const TitleGroupApprovalColumn = (row: Row<IGroupApproval>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

const TitleMyFavoriteColumn = (row: Row<IMyFavorites>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

const TitleMyLatestColumn = (row: Row<ILatestDocuments>) => {
    const {
        original: { isConfidential, title },
    } = row;

    return (
        <>
            {isConfidential && <span className="confidential">Confidential: </span>}
            <SkeletonText text={title} width={200} />
        </>
    );
};

// eslint-disable-next-line max-lines-per-function
export const FavoriteColumn = ({
    docId,
    isActive,
    type,
    status,
    updateResults,
}: {
    docId: number;
    isActive: boolean;
    type: IDashboardTabs;
    status: IDocStatus;
    updateResults: () => void;
}): JSX.Element => {
    const onClick = async () => {
        updateResults();
        if (isActive) {
            const res = await removeFromFavorites(docId);
            if (res?.apiStatus === 'SUCCESS') {
                updateResults();
            }
        } else {
            const res = await addToFavorites(docId);
            if (res?.apiStatus === 'SUCCESS') {
                updateResults();
            }
        }
    };

    return (
        <div className="favorite-column">
            {((type === 'MY_DOCUMENTS' && status === 'APPROVED') ||
                type === 'MY_FAVORITES' ||
                type === 'MY_LATEST') && (
                    <>
                        <StarIconSvg
                            data-tip={isActive ? 'Unsubscribe' : 'Subscribe and favorite the document'}
                            data-for="tooltip"
                            className={`star ${isActive ? 'active' : ''} `}
                            onClick={onClick}
                        />
                        <Tooltip id="tooltip" place="top" />
                    </>
                )}
        </div>
    );
};

export const MY_APPROVAL_COLUMN: Column<IMyApproval>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberColumn {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleMyApprovalColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Requested On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Author Name',
        accessor: 'authorName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        disableSortBy: true,
        minWidth: 15,
    },
    {
        Header: 'Status',
        accessor: 'approverState',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
];

export const GROUP_APPROVALS_COLUMN: Column<IGroupApproval>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberGroupColumn {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleGroupApprovalColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Requested On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    // {
    //     Header: 'Deadline',
    //     accessor: 'deadline',
    //     Cell: ({ value }) => {
    //         if(value === 'N/A') {
    //             return 'N/A';
    //         }
    //         return <DateColumn value={value} />
    //     },
    //     minWidth: DATE_MIN_WIDTH,
    // },
    {
        Header: 'Author Name',
        accessor: 'authorName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        disableSortBy: true,
        minWidth: 15,
    },
    {
        Header: 'Approver',
        accessor: 'approver',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Group',
        accessor: 'groupName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Status',
        accessor: 'approverState',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
];

export const MY_DOCUMENT_COLUMNS: Column<IMyDocument>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberDocument {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleMyDocumentColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Created On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Approved On',
        accessor: 'approvedOn',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        minWidth: 15,
        disableSortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
    {
        id: 'favorite-icon',
        accessor: 'docId',
        Cell: ({ row, updateResults }) => (
            <FavoriteColumn
                docId={row.original.docId}
                status={row.original.status}
                isActive={row.original.isFavorite}
                type="MY_DOCUMENTS"
                updateResults={updateResults}
            />
        ),
        minWidth: 10,
        disableSortBy: true,
    },
];

export const MY_REVIEWS_COLUMN: Column<IMyReviews>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberForReviews {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ value }) => <SkeletonText text={value} width={150} />,
        minWidth: 150,
    },
    {
        Header: 'Requested On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Author Name',
        accessor: 'authorName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        disableSortBy: true,
        minWidth: 15,
    },
    {
        Header: 'Status',
        accessor: 'approverState',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
];

export const MY_FAVORITE_COLUMN: Column<IMyFavorites>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberForFavorite {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleMyFavoriteColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Created On',
        accessor: 'createdDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Author Name',
        accessor: 'authorName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        minWidth: 15,
        disableSortBy: true,
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => <StatusColumn value={value} />,
        minWidth: 140,
    },
    {
        id: 'favorite-icon',
        accessor: 'docId',
        Cell: ({ row, updateResults }) => (
            <FavoriteColumn
                docId={row.original.docId}
                status={row.original.status}
                isActive
                type="MY_FAVORITES"
                updateResults={updateResults}
            />
        ),
        disableSortBy: true,
        minWidth: 10,
    },
];

export const LATEST_DOCUMENTS_COLUMN: Column<ILatestDocuments>[] = [
    {
        Header: 'Doc Number',
        accessor: 'docNumber',
        Cell: ({ row }) => <DocNumberForLatest {...row} />,
        minWidth: 250,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleMyLatestColumn {...row} />,
        minWidth: 150,
    },
    {
        Header: 'Published On',
        accessor: 'updatedAt',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: DATE_MIN_WIDTH,
    },
    {
        Header: 'Author Name',
        accessor: 'authorName',
        Cell: ({ value }) => <SkeletonText text={value} width={100} />,
        minWidth: 200,
    },
    {
        Header: 'Files',
        accessor: 'files',
        Cell: ({ value }) => <FilesColumn value={value} />,
        minWidth: 15,
        disableSortBy: true,
    },
    // {
    //     Header: 'Status',
    //     accessor: 'status',
    //     Cell: ({ value }) => <StatusColumn value={value} />,
    //     minWidth: 140,
    // },
    {
        id: 'favorite-icon',
        accessor: 'docId',
        Cell: ({ row, updateResults }) => (
            <FavoriteColumn
                docId={row.original.docId}
                status={row.original.status}
                isActive={row.original.isFavorite}
                type="MY_LATEST"
                updateResults={updateResults}
            />
        ),
        disableSortBy: true,
        minWidth: 10,
    },
];
