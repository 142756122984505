/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './DocumentAction.scss';
import {Tooltip} from 'react-tooltip';
import { IButtonStatus, IDocStatus, IUserType } from '../../interfaces';
import { createComment, getComments } from '../../API/comment';
import {
    createEventForEditDocument,
    documentAction,
    documentActionClaimUnclaim,
    getAllDocVersionIds,
    getDocumentRelationsInfo,
    getDocumentsDetails,
    toggleDocumentLock,
    updateAutoRenew,
    updateDCOfields,
} from '../../API/document';

import {
    IDocReferenceInfo,
    IDocumentActionTypes,
    IDocumentInfo,
    IVersionNumberInfo,
} from '../../interfaces/document';
import CONSTANTS from '../../constants';
import ActionModal from './ActionModal';
import ReviseModal from './ReviseModal';
import ForwardDocModal from './ForwardDocModal';
import AddApproverModal from './AddApproverModal';
import STATIC_CONTENT from '../../constants/StaticContent';
import Button from '../button/Button';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { useTaskDetailContext } from '../../contexts/task-details-provider';
import RepublishModal from './RepublishModal';
import { useDocumentContext } from '../../contexts/document-provider';
import TerminateModal from './TerminateModal';
import DocusignModal from './DocusignModal';

interface Props {
    userType: IUserType;
    documentId: number;
    docState: IDocStatus;
    activeDocumentVersion: number;
    hideUsers: number[];
    setHideUsers: React.Dispatch<React.SetStateAction<number[]>>;
    collectComment?: boolean;
    collectCommentRejection?: boolean;
    taskId?: string;
    assigneeAndCreatorSame?: boolean;
    unsavedComment: string;
    setUnsavedCommentFunction: any;
    render: boolean;
    assignee: {
        employeeName: string;
        id: number;
        email: string;
    };
    accessibleByCurrentUser?: boolean;
    isSunsetEnabled?: boolean;
    autoRenew: boolean;
    docInfo: IDocumentInfo;
    setDocInfo: React.Dispatch<React.SetStateAction<IDocumentInfo>>;
    isEndDateAsked: boolean;
    isDocusignTriggered?: boolean;
}

const { DOCUMENT_ACTIONS, DOC_COMMENTS, DOC_TYPE_REVISE } = STATIC_CONTENT;

const DocumentAction: React.FC<Props> = ({
    userType,
    documentId,
    docState,
    activeDocumentVersion,
    hideUsers,
    collectComment,
    collectCommentRejection,
    taskId,
    setHideUsers,
    assigneeAndCreatorSame,
    unsavedComment,
    setUnsavedCommentFunction,
    render,
    assignee,
    accessibleByCurrentUser,
    isSunsetEnabled,
    autoRenew,
    docInfo,
    setDocInfo,
    isEndDateAsked,
    isDocusignTriggered,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { fetchTaskDetails, updateFields, approvers } = useTaskDetailContext();
    const docTypeInfo1 = useTaskDetailContext().docTypeInfo;
    const { fetchDocumentVersion, versionNumberInfo } = useDocumentContext();
    const { user } = useAuthDataContext();
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [forwardModal, setForwardModal] = useState(false);
    const [addApprovalModal, setAddApprovalModal] = useState(false);
    const [isReviseModalOpen, setIsReviseModalOpen] = useState(false);
    const [isRepublishModalOpen, setIsRepublishModal] = useState(false);
    const [isTerminateModalOpen, setIsTerminateModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { isPublishLimitReached, activeVersionOwnerInfo, fieldsData, docTypeInfo } =
        useDocumentContext();
    const isDCOwithApprovers1 = useTaskDetailContext().isDCOwithApprovers;
    const isDCOwithApprovers2 = useDocumentContext().isDCOwithApprovers;
    const isDCOwithApprovers = isDCOwithApprovers1 || isDCOwithApprovers2;
    const [actionType, setActionType] = useState<IDocumentActionTypes>('');
    const isContract = docTypeInfo.isContract || docTypeInfo1.isContract;
    const isDCO = docTypeInfo.isDCO || docTypeInfo1.isDCO;
    const onCheckoutClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const customVersionObj: IVersionNumberInfo = {
            minorVersionNumber: versionNumberInfo.minorVersionNumber + 1,
            majorVersionNumber: versionNumberInfo.majorVersionNumber,
        };
        const res = await documentAction(
            documentId,
            type,
            docTypeInfo,
            undefined,
            'RERELEASE',
            undefined,
            customVersionObj,
        );
        if (res?.apiStatus === 'SUCCESS') {
            if (documentId && activeDocumentVersion) {
                const responseData = await getDocumentRelationsInfo(Number(activeDocumentVersion));
                if (responseData?.apiStatus === 'SUCCESS') {
                    const relationArr: any[] = responseData.data;
                    const draftRelationArr = relationArr.filter((obj) => obj?.state === 'DRAFT');
                    if (draftRelationArr.length > 0) {
                        const versionIdList = await getAllDocVersionIds([documentId]);
                        const docVersionIds = versionIdList
                            ? versionIdList?.documentVersionIds
                            : [];
                        const NewVersionData = await getDocumentsDetails(docVersionIds);
                        const DCOList = draftRelationArr.map((obj) => obj.activeVersionId);
                        const res1 = await updateDCOfields(
                            DCOList,
                            NewVersionData?.data,
                            documentId,
                        );
                        if (res1?.apiStatus === 'SUCCESS') {
                            toast.success(
                                'Reference has been updated to this new version in all the draft DCOs this document is part of',
                            );
                        }
                    }
                }
            }
            // toast.success(DOC_TYPE_REVISE.TOAST.SUCCESS_MSG.REVISED_SUCCESSFULLY);
            navigate(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
        } else {
            toast.error(DOC_TYPE_REVISE.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };
    const onClick = async (type: IDocumentActionTypes) => {
        docTypeInfo.isContract = (docTypeInfo1.isContract || docTypeInfo.isContract);
        setButtonStatus('LOADING');
        await updateFields();
        // first save the comments if any
        if ((type === 'APPROVE' || type === 'REJECT') && unsavedComment) {
            const commentRes = await createComment(
                activeDocumentVersion,
                unsavedComment,
                docTypeInfo.allowReviseByAnyone,
            );
            if (commentRes?.apiStatus === 'SUCCESS') {
                setUnsavedCommentFunction('');
            } else {
                toast.error(DOC_COMMENTS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
        }
        let res;
        if (type === 'TERMINATE') {
            res = await documentAction(documentId, 'TERMINATE', docTypeInfo, undefined, undefined, {
                effectiveFromDate: docInfo.effectiveFrom,
                endDate: isEndDateAsked,
                effectiveTillDate: docInfo.effectiveTill,
                effectivePeriod: docInfo.effectivePeriod,
                sunsetPeriod: docInfo.sunsetPeriod,
            });
        } else if (type === 'OBSOLETE') {
            res = await documentAction(documentId, 'REVISE', docTypeInfo, taskId, 'OBSOLETE');
        } else {
            res = await documentAction(documentId, type, docTypeInfo, taskId);
        }
        if (res?.apiStatus === 'SUCCESS') {
            if (type === 'APPROVE' || type === 'REJECT') {
                toast.success(
                    `${type === 'APPROVE' ? 'Approved' : 'Rejected'} ${isDCO ? 'DCO' : 'Document'}`,
                );
                const searchParams = new URLSearchParams(location.search);
                const prevPageType = searchParams.get('prev_page_type');
                const taskIdSearch = searchParams.get('task_id');
                if (
                    prevPageType === 'MY_GROUP_APPROVALS' ||
                    docTypeInfo1.prefixCode?.includes('DCO')
                )
                    navigate(
                        `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}`,
                    );
                else if (taskIdSearch)
                    navigate(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_APPROVALS`);
                else {
                    await fetchDocumentVersion();
                    await getComments(activeDocumentVersion, docTypeInfo.allowReviseByAnyone);
                }
            } else if (type === 'WITHDRAW') {
                // unlock children if any
                // const childDocIds: number[] = [];
                // fieldsData.forEach((fieldData) => {
                //     if (fieldData.name === 'documents') {
                //         fieldData.value.forEach((currDoc: IDocReferenceInfo) => {
                //             childDocIds.push(currDoc.id);
                //         });
                //     }
                // });

                // if (childDocIds.length) {
                //     await Promise.all(
                //         childDocIds.map(async (currId) => {
                //             await toggleDocumentLock(false, Number(currId));
                //         }),
                //     );
                // };

                if (isDCO) {
                    toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.CHANGE_ORDER_WITHDRAWN);
                } else {
                    toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.DOCUMENT_WITHDRAWN);
                }
                if (
                    (user.isAdmin || user.isDcoAdmin) &&
                    userType !== 'CREATOR' &&
                    user.id !== activeVersionOwnerInfo?.id
                ) {
                    navigate(
                        `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}?doc_id=${documentId}`,
                    );
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                } else {
                    navigate(
                        `${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`,
                    );
                }
            } else if (type === 'REVIEWED') {
                toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.DOCUMENT_REVIEWED);
                navigate(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}?type=MY_REVIEWS`);
            } else if (type === 'OBSOLETE') {
                toast.success(DOC_TYPE_REVISE.TOAST.OBSOLETE_MSG.OBSOLETE_STARTED);
                navigate(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
            } else {
                toast.success(DOCUMENT_ACTIONS.TOAST.SUCCESS_MSG.SUCCESS);
            }
        } else {
            toast.error(DOCUMENT_ACTIONS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };

    const editDocument = async () => {
        await createEventForEditDocument(documentId, user.id);
        setButtonStatus('LOADING');
        navigate(`/${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`, {
            state: accessibleByCurrentUser,
        });
    };

    const toggleClaim = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const res = await documentActionClaimUnclaim(type, taskId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(
                `${type === 'CLAIM' ? 'Claimed' : 'Unclaimed'} ${isDCO ? 'DCO' : 'Document'}`,
            );
            await fetchTaskDetails();
            await fetchDocumentVersion();
            setButtonStatus('DEFAULT');
        } else {
            toast.error(DOCUMENT_ACTIONS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
    };
    return (
        <div className="document-action">
            <div className="button-group">
                {(user.isAdmin || (user.isDcoAdmin && isDCO)) &&
                    (docState === 'UNDER_REVIEW' ||
                        (userType === 'CREATOR' &&
                            user.id === activeVersionOwnerInfo?.id &&
                            docState === 'APPROVED')) && (
                        <>
                            <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('WITHDRAW');
                                    onClick('WITHDRAW');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'WITHDRAW'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.WITHDRAW}
                            </Button>
                        </>
                    )}
                {userType === 'CREATOR' &&
                    user.id === activeVersionOwnerInfo?.id &&
                    (docState === 'UNDER_REVIEW' || docState === 'APPROVED') &&
                    !user.isAdmin &&
                    !(user.isDcoAdmin && isDCO) && (
                        <>
                            <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('WITHDRAW');
                                    onClick('WITHDRAW');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'WITHDRAW'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.WITHDRAW}
                            </Button>
                        </>
                    )}
                {userType !== 'CREATOR' &&
                    (user.id === docInfo.submitter.id || user.id === activeVersionOwnerInfo?.id) &&
                    (docState === 'UNDER_REVIEW' || docState === 'APPROVED') &&
                    !user.isAdmin &&
                    !(user.isDcoAdmin && isDCO) && (
                        <>
                            <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('WITHDRAW');
                                    onClick('WITHDRAW');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'WITHDRAW'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.WITHDRAW}
                            </Button>
                        </>
                    )}
                {(docState === 'PUBLISHED' || docState === 'SUNSET') && !docTypeInfo.blockRevise && (
                    <>
                        {docTypeInfo.allowDCO ?
                            <Button
                                type="submit"
                                className="primary-btn"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REVISE');
                                    onCheckoutClick('REVISE');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REVISE'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.CHECKOUT}
                            </Button> :
                            ((user.id === activeVersionOwnerInfo?.id || docTypeInfo.allowReviseByAnyone) && <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REVISE');
                                    setIsReviseModalOpen(true);
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REVISE'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.REVISE}
                            </Button>)}
                        {(docTypeInfo.allowDCO || docTypeInfo.allowObsolete) && (
                            <Button
                                type="submit"
                                className={`primary-btn ${(docTypeInfo.allowObsolete && !docTypeInfo.allowDCO) ? 'edit' : ''}`}
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    onClick('OBSOLETE');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'OBSOLETE'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.OBSOLETE}
                            </Button>
                        )}
                    </>
                )}
                {userType === 'CREATOR' &&
                    docState === 'SUNSET' &&
                    isSunsetEnabled &&
                    !isEndDateAsked && (
                        <>
                            <Button
                                type="submit"
                                data-for="republish"
                                data-tip="The max Republish limit has been reached"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING' || isPublishLimitReached}
                                onClick={() => {
                                    setActionType('REPUBLISH');
                                    setIsRepublishModal(true);
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                            >
                                {DOCUMENT_ACTIONS.BUTTON.REPUBLISH}
                            </Button>
                            <Tooltip id="republish" place="right" />
                        </>
                    )}
                {userType === 'CREATOR' && docState === 'PUBLISHED' && docInfo.isEverGreen && (
                    <>
                        <Button
                            type="submit"
                            data-for="republish"
                            data-tip="The max Republish limit has been reached"
                            className="primary-btn edit"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={async () => {
                                await onClick('TERMINATE');
                                await fetchDocumentVersion();
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                        >
                            {DOCUMENT_ACTIONS.BUTTON.TERMINATE}
                        </Button>
                        <Tooltip id="republish" place="right" />
                    </>
                )}
                {userType === 'CREATOR' && docState === 'SUNSET' && isSunsetEnabled && autoRenew && (
                    <>
                        <Button
                            type="submit"
                            data-for="republish"
                            data-tip="The max Republish limit has been reached"
                            className="primary-btn edit"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={async () => {
                                setDocInfo({ ...docInfo, autoRenew: false });
                                await updateAutoRenew(documentId, activeDocumentVersion);
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'REPUBLISH'}
                        >
                            Prevent Renewal
                        </Button>
                        <Tooltip id="republish" place="right" />
                    </>
                )}
                {userType === 'APPROVER' && docState === 'UNDER_REVIEW' && !render && (
                    <>
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn approve"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('APPROVE');
                                    if (collectComment) {
                                        setIsModalOpen(true);
                                    } else {
                                        onClick('APPROVE');
                                    }
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'APPROVE'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.APPROVE}</h4>
                            </Button>
                        )}
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn reject"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REJECT');
                                    if (collectCommentRejection) {
                                        setIsModalOpen(true);
                                    } else {
                                        onClick('REJECT');
                                    }
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REJECT'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.REJECT}</h4>
                            </Button>
                        )}
                        {accessibleByCurrentUser && !isDCO && (
                            <Button
                                type="submit"
                                className="primary-btn forward"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    setButtonStatus('LOADING');
                                    setButtonStatus('DEFAULT');
                                    setActionType('EDIT');
                                    editDocument();
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'EDIT'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.EDIT}</h4>
                            </Button>
                        )}
                        <Button
                            type="submit"
                            className="primary-btn forward"
                            disabled={buttonStatus === 'LOADING'}
                            onClick={async () => {
                                setButtonStatus('LOADING');
                                setButtonStatus('DEFAULT');
                                setActionType('FORWARD');
                                setForwardModal(true);
                                setActionType('FORWARD');
                            }}
                            isLoading={buttonStatus === 'LOADING' && actionType === 'FORWARD'}
                        >
                            <h4>{DOCUMENT_ACTIONS.BUTTON.REASSIGN}</h4>
                        </Button>
                        {!assigneeAndCreatorSame && !isContract && !isDCO && (
                            <Button
                                type="submit"
                                className="primary-btn ad-hoc"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={async () => {
                                    setButtonStatus('LOADING');
                                    setButtonStatus('DEFAULT');
                                    setActionType('AD_HOC');
                                    setAddApprovalModal(true);
                                    setActionType('AD_HOC');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'AD_HOC'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.ADD_APPROVER}</h4>
                            </Button>
                        )}
                    </>
                )}
                {render && isDocusignTriggered && docTypeInfo1.isContract && (
                    <>
                        <h4>
                            Please sign the contract sent out to you through Email to approve this
                            Contract
                        </h4>
                    </>
                )}
                {render && !isDocusignTriggered && (
                    <>
                        {assignee && Object.keys(assignee).length !== 0 && user.id === assignee.id && (
                            <div>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('APPROVE');
                                        if (collectComment || (!isDCOwithApprovers && isDCO)) {
                                            if (!isDCOwithApprovers)
                                                if (isDCO) {
                                                    toast.warning(
                                                        `The DCO will be published. There are no additional approvers`,
                                                    );
                                                } else {
                                                    toast.warning(
                                                        `The document will be published. There are no additional approvers`,
                                                    );
                                                }
                                            setIsModalOpen(true);
                                        } else {
                                            onClick('APPROVE');
                                        }
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'APPROVE'
                                    }
                                >
                                    <h4>{DOCUMENT_ACTIONS.BUTTON.APPROVE}</h4>
                                </Button>
                                <Button
                                    type="submit"
                                    className="primary-btn reject"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('REJECT');
                                        if (collectCommentRejection) {
                                            setIsModalOpen(true);
                                        } else {
                                            onClick('REJECT');
                                        }
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'REJECT'
                                    }
                                >
                                    <h4>{DOCUMENT_ACTIONS.BUTTON.REJECT}</h4>
                                </Button>
                                {accessibleByCurrentUser && !isDCO && (
                                    <Button
                                        type="submit"
                                        className="primary-btn forward"
                                        disabled={buttonStatus === 'LOADING'}
                                        onClick={async () => {
                                            setButtonStatus('LOADING');
                                            setButtonStatus('DEFAULT');
                                            setActionType('EDIT');
                                            editDocument();
                                        }}
                                        isLoading={
                                            buttonStatus === 'LOADING' && actionType === 'EDIT'
                                        }
                                    >
                                        <h4>{DOCUMENT_ACTIONS.BUTTON.EDIT}</h4>
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={async () => {
                                        setButtonStatus('LOADING');
                                        setActionType('UNCLAIM');
                                        toggleClaim('UNCLAIM');
                                    }}
                                    isLoading={
                                        buttonStatus === 'LOADING' && actionType === 'UNCLAIM'
                                    }
                                >
                                    <h4>Unclaim</h4>
                                </Button>
                                {!isContract && !isDCO && (
                                    <Button
                                        type="submit"
                                        className="primary-btn ad-hoc"
                                        disabled={buttonStatus === 'LOADING'}
                                        onClick={async () => {
                                            setButtonStatus('LOADING');
                                            await updateFields();
                                            setButtonStatus('DEFAULT');
                                            setActionType('AD_HOC');
                                            setAddApprovalModal(true);
                                            setActionType('AD_HOC');
                                        }}
                                        isLoading={
                                            buttonStatus === 'LOADING' && actionType === 'AD_HOC'
                                        }
                                    >
                                        <h4>{DOCUMENT_ACTIONS.BUTTON.ADD_APPROVER}</h4>
                                    </Button>
                                )}
                            </div>
                        )}
                        {assignee && Object.keys(assignee).length === 0 && (
                            <div>
                                <span>Currently Unclaimed</span>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={async () => {
                                        setActionType('CLAIM');
                                        toggleClaim('CLAIM');
                                    }}
                                    isLoading={buttonStatus === 'LOADING' && actionType === 'CLAIM'}
                                >
                                    <h4>Claim</h4>
                                </Button>
                            </div>
                        )}
                        {assignee && Object.keys(assignee).length !== 0 && user.id !== assignee.id && (
                            <div>
                                <span>{`Currently claimed by : ${assignee.employeeName}`}</span>
                                <Button
                                    type="submit"
                                    className="primary-btn approve"
                                    disabled={buttonStatus === 'LOADING'}
                                    onClick={() => {
                                        setActionType('CLAIM');
                                        toggleClaim('CLAIM');
                                    }}
                                    isLoading={buttonStatus === 'LOADING' && actionType === 'CLAIM'}
                                >
                                    <h4>Claim</h4>
                                </Button>
                            </div>
                        )}
                    </>
                )}
                {userType === 'REVIEWER' && docState === 'UNDER_REVIEW' && (
                    <>
                        {!assigneeAndCreatorSame && (
                            <Button
                                type="submit"
                                className="primary-btn edit"
                                disabled={buttonStatus === 'LOADING'}
                                onClick={() => {
                                    setActionType('REVIEWED');
                                    onClick('REVIEWED');
                                }}
                                isLoading={buttonStatus === 'LOADING' && actionType === 'REVIEWED'}
                            >
                                <h4>{DOCUMENT_ACTIONS.BUTTON.REVIEWED}</h4>
                            </Button>
                        )}
                    </>
                )}
            </div>
            {isReviseModalOpen && (
                <ReviseModal
                    isModalOpen={isReviseModalOpen}
                    onModalClose={setIsReviseModalOpen}
                    documentId={documentId}
                    activeDocumentVersion={activeDocumentVersion}
                />
            )}
            {openModal && (
                <DocusignModal
                    isModalOpen={openModal}
                    onModalClose={setOpenModal}
                    documentId={documentId}
                />
            )}
            {isRepublishModalOpen && (
                <RepublishModal
                    isModalOpen={isRepublishModalOpen}
                    onModalClose={setIsRepublishModal}
                    documentId={documentId}
                />
            )}
            {isTerminateModalOpen && (
                <TerminateModal
                    isModalOpen={isTerminateModalOpen}
                    onModalClose={setIsTerminateModal}
                    documentId={documentId}
                />
            )}
            {isModalOpen && (
                <ActionModal
                    isModalOpen={isModalOpen}
                    onModalClose={setIsModalOpen}
                    type={actionType}
                    isDCOwithApprovers={isDCOwithApprovers}
                    collectComment={collectComment}
                    collectCommentRejection={collectCommentRejection}
                    onClick={onClick}
                    activeDocVersion={activeDocumentVersion}
                    allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                />
            )}
            {forwardModal && (
                <ForwardDocModal
                    documentId={documentId}
                    isModalOpen={forwardModal}
                    onModalClose={setForwardModal}
                    hideUsers={hideUsers}
                    taskId={taskId || ''}
                    activeDocumentVersion={activeDocumentVersion}
                    unsavedComment={unsavedComment}
                    setUnsavedCommentFunction={setUnsavedCommentFunction}
                />
            )}
            {addApprovalModal && (
                <AddApproverModal
                    documentId={documentId}
                    isModalOpen={addApprovalModal}
                    onModalClose={setAddApprovalModal}
                    hideUsers={hideUsers}
                    setHideUsers={setHideUsers}
                    taskId={taskId || ''}
                />
            )}
        </div>
    );
};

export default DocumentAction;
