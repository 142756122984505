/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDocumentTypeVersion } from '../../API/document-type';
import CONSTANTS from '../../constants';
import { useDocTypeContext } from '../../contexts/document-type-provider';
import './DocTypeCreateInfo.scss';
import STATIC_CONTENT from '../../constants/StaticContent';

const { DOC_TYPE_CREATE_INFO } = STATIC_CONTENT;

const DocTypeCreateInfo: React.FC = () => {
    const [reviseBtnLoading, setReviseBtnLoading] = useState(false);
    const navigate = useNavigate();
    const {
        onSaveDraft,
        onSubmit,
        changeDocTypeInfo,
        docTypeInfo,
        publishBtnLoading,
        documentTypeId,
        docTypeErrors,
    } = useDocTypeContext();

    const onReviseClick = async () => {
        setReviseBtnLoading(true);
        const res = await createDocumentTypeVersion(documentTypeId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(DOC_TYPE_CREATE_INFO.TOAST.SUCCESS_MSG.REVISED_SUCCESSFULLY);
            navigate({
                pathname: `/${CONSTANTS.RELATIVE_PATHS.documentTypeCreate.Url}`,
                search: `?doc_type_id=${documentTypeId}&doc_type_v_id=${res.docTypeVersionId}`,
            });
        } else {
            toast.error(DOC_TYPE_CREATE_INFO.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setReviseBtnLoading(false);
    };

    return (
        <div className="doc-type-create-info">
            {docTypeInfo.status === 'DRAFT' ? (
                <>
                    <div className="top-section">
                        <h1>{DOC_TYPE_CREATE_INFO.LABEL.NEW_DOC_TYPE}</h1>
                        <div className="buttons">
                            <button type="button" onClick={() => onSaveDraft()}>
                                {DOC_TYPE_CREATE_INFO.BUTTON.SAVE_DRAFT}
                            </button>
                            <button
                                type="button"
                                onClick={() => onSubmit()}
                                className="primary-btn"
                                disabled={publishBtnLoading || !documentTypeId}
                            >
                                {DOC_TYPE_CREATE_INFO.BUTTON.PUBLISH}
                            </button>
                        </div>
                    </div>
                    <div className="section">
                        <div>
                            <label htmlFor="document-title">
                                {DOC_TYPE_CREATE_INFO.LABEL.DOCUMENT_TYPE_NAME}
                            </label>
                            <input
                                className={`${docTypeErrors.title ? 'error' : ''}`}
                                type="text"
                                name="document-title"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Document Type Title"
                                value={docTypeInfo.title}
                                onChange={(e) => changeDocTypeInfo('title', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div>
                            <label htmlFor="document-description">
                                {DOC_TYPE_CREATE_INFO.LABEL.DESCRIPTION}
                            </label>
                            <textarea
                                className={`${docTypeErrors.description ? 'error' : ''}`}
                                name="document-title"
                                autoComplete="off"
                                autoCapitalize="on"
                                placeholder="Enter Description"
                                value={docTypeInfo.description}
                                onChange={(e) => changeDocTypeInfo('description', e.target.value)}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="doc-type-create-info__header">
                        <div className="left-section">
                            <h1>{docTypeInfo.title}</h1>
                            <p>{docTypeInfo.description}</p>
                        </div>
                        <div className="buttons">
                            <button
                                type="button"
                                disabled={reviseBtnLoading}
                                onClick={() => onReviseClick()}
                            >
                                {DOC_TYPE_CREATE_INFO.BUTTON.REVISE}
                            </button>
                        </div>
                    </div>
                    <div className="doc-type-create-info__version">
                        {/* <label>Version</label> */}
                        {/* <Select
                            name="version"
                            id="version"
                            isMulti={false}
                            className="react-select"
                            classNamePrefix="select"
                            options={docTypeVersions}
                            value={
                                {
                                    label: docTypeInfo.version,
                                    value: docTypeInfo.version,
                                } as IOptionValue
                            }
                            onChange={(option) => {
                                if (option) changeDocTypeInfo('version', option?.value as string);
                            }}
                        /> */}
                    </div>
                </>
            )}
            <p className="note">
                <i>
                    <b>Note:</b> {DOC_TYPE_CREATE_INFO.NOTE}
                </i>
            </p>
        </div>
    );
};

export default DocTypeCreateInfo;
