import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { DuplicateIcon, ReportHistory, SortIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IReportsList } from '../../interfaces/reports';
import { DateTimeColumn, NoDocumentsFound } from '../General';
import { SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';
import './ReportListTablePage.scss';

const columnMapping: IStringObject = {
    creator: 'creator',
    title: 'title',
};

const DEFAULT_SORT: ISort = {
    id: 'createdAt',
    desc: true,
};

const TitleReportColumn = (row: Row<IReportsList>) => {
    const {
        original: { title, id },
    } = row;
    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname: '/reportList/report',
                search: `?report_id=${id}&report_name=${title}&prev_page_type=STANDARD_REPORT`,
            }}
        >
            <SkeletonText text={title} width={200} />
        </Link>
    );
};

const ActionColumn = (row: Row<IReportsList>) => {
    const {
        original: { id, title },
    } = row;
    const navigate = useNavigate();
    return (
        <div className="action-icons">
            {/* <DuplicateIcon style={{ cursor: 'pointer' }} title="Duplicate Report" /> */}
            <ReportHistory
                style={{ cursor: 'pointer' }}
                title="Report History"
                onClick={() =>
                    navigate(`/reportList/detail-summary?report_id=${id}&report_name=${title}`)
                }
            />
        </div>
    );
};

const COLUMNS: Column<IReportsList>[] = [
    {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => i + 1,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleReportColumn {...row} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: true,
    },
    {
        Header: 'Creator',
        accessor: 'creator',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 250,
        disableSortBy: false,
    },
    {
        Header: 'Creation Date',
        accessor: 'createdAt',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 200,
        disableSortBy: false,
    },
    // {
    //     Header: 'Action',
    //     accessor: 'id',
    //     Cell: ({ row }) => <ActionColumn {...row} />,
    //     minWidth: 300,
    //     disableSortBy: true,
    // },
];

interface Props {
    data: IReportsList[];
    pagination: IPaginationData;
    fetchReports: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

// eslint-disable-next-line max-lines-per-function
const StandardReportListTablePage: React.FC<Props> = ({ data, pagination, fetchReports }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IReportsList>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchReports(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'createdAt',
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);
    return (
        <div className="reports-list-page-table">
            <div className="table-section">
                {page.length > 0 && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted && (
                                                    <SortIcon
                                                        className={`sort ${column.isSortedDesc ? 'desc' : 'asc'
                                                            } `}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {page.length === 0 && <NoDocumentsFound heading="No Data Found" />}
            </div>
            {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    );
};

export default StandardReportListTablePage;