/* eslint-disable max-lines-per-function */
import { format } from 'date-fns';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { ChevronDoubleDown, ChevronDoubleUp } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { Oval } from 'react-loader-spinner';
import Modal from 'react-modal';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import {
    createCustomReport,
    getAllReports,
    getCustomerReportById,
    requestExcelGeneration,
    updateCustomReport,
} from '../../API/reports';
import { DeleteSvg, PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import { FilterProps, FilterRow } from '../../interfaces/reports';
import { formatDateTime } from '../../utils/date';
import '../contracts/ContractsTableFilters.scss';
import CustomReportModal from './CustomReportModal';

interface Props {
    handleReset: () => void;
    setFilterParams: (option: any) => void;
    attributeOption: { value: string; label: string }[];
}

const ReportsTableFilters: React.FC<Props> = ({
    handleReset,
    setFilterParams,
    attributeOption,
}) => {
    const operatorOption = [
        [
            { value: 'contain', label: 'Contains' },
            { value: 'notContain', label: 'Does Not Contain' },
            { value: 'eq', label: 'Is Equal To' },
            { value: 'neq', label: 'Is Not Equal To' },
            { value: 'startsWith', label: 'Starts With' },
            { value: 'notStartWith', label: 'Does Not Start With' },
        ],
        [
            { value: 'dateeq', label: 'Is Equal To' },
            { value: 'isBefore', label: 'Is Before' },
            { value: 'isAfter', label: 'Is After' },
            { value: 'isBetween', label: 'Is Between' },
            { value: 'isToday', label: 'Is Today' },
            { value: 'isWeek', label: 'Last 7 Days' },
            { value: 'isMonth', label: 'Last 30 Days' },
            { value: 'isTwoMonth', label: 'Last 60 Days' },
            { value: 'isSixMonth', label: 'Last 6 Months' },
            { value: 'isTwelveMonths', label: 'Last 12 Months' },
        ],
        [
            { value: 'numEq', label: 'Is Equal To' },
            { value: 'numNeq', label: 'Is Not Equal To' },
            { value: 'lte', label: 'Less Than or Equal To' },
            { value: 'gte', label: 'Greater Than or Equal To' },
            { value: 'isBetween', label: 'Is Between' },
        ],
    ];
    const dateOperatorArray = [
        'isToday',
        'isWeek',
        'isMonth',
        'isTwoMonth',
        'isSixMonth',
        'isTwelveMonths',
    ];
    const defaultFilterRow = {
        id: 1,
        attribute: { value: '', label: 'Select...' },
        operator: { value: '', label: 'Select...' },
        value: '',
        isDateFilter: false,
        isNumberFilter: false,
        date: {
            dateValue: null,
            dateStartValue: null,
            dateEndValue: null,
        },
        number: {
            numberValue: null,
            numberStartValue: null,
            numberEndValue: null,
        },
    };
    const [filterRows, setFilterRows] = useState<FilterRow[]>([defaultFilterRow]);
    const [showFilters, setShowFilters] = useState(true);
    const [searchedFilters, setSearchedFilters] = useState<FilterRow[]>([]);
    const [showSaveFilter, setShowSaveFilter] = useState(false);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportId = queryParams.get('report_id');
    const reportName = queryParams.get('report_name');
    const [showModal, setShowModal] = useState(false);
    const [saveFilterLoading, setSaveFilterLoading] = useState(false);
    const [customReportData, setCustomReportData] = useState({
        title: '',
        description: '',
    });
    const [searchButtonClicked, setSearchButtonClicked] = useState(false);
    const navigate = useNavigate();
    const [exportReportLoading, setExportReportLoading] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [exportFilters, setExportFilters] = useState<
        {
            attribute: string;
            operator: string;
            attributeValue: string;
            dateValue: number | null;
            dateStartValue: number | null;
            dateEndValue: number | null;
            numberValue: number | null;
            numberStartValue: number | null;
            numberEndValue: number | null;
        }[]
    >([]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCustomReportData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (search.includes('CUSTOM_REPORT')) {
            setShowSaveFilter(true);
        }
    }, []);

    const handleAttributeChange = (option: any, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const isDateFilter =
                    option.value === 'updatedAt' ||
                    option.value === 'createdAt' ||
                    option.value === 'submittedOn' ||
                    option.value === 'publishedOn' ||
                    option.value === 'ChangeAdministratorDecisionTime' ||
                    option.value === 'approverDecisionTime';
                const isNumberFilter =
                    option.value === 'submitCount' ||
                    option.value === 'AdminResponseTimeHours' ||
                    option.value === 'approvalDurationDays' ||
                    option.value === 'approverResponseTimeDays';
                if (isDateFilter) {
                    return {
                        ...row,
                        isDateFilter,
                        isNumberFilter,
                        attribute: option,
                        operator: { value: '', label: 'Select...' },
                        value: '',
                        date: {
                            dateValue: new Date().getTime() / 1000,
                            dateStartValue: null,
                            dateEndValue: null,
                        },
                    };
                }
                if (isNumberFilter) {
                    return {
                        ...row,
                        isDateFilter,
                        isNumberFilter,
                        attribute: option,
                        operator: { value: '', label: 'Select...' },
                        value: '',
                        number: {
                            numberValue: null,
                            numberStartValue: null,
                            numberEndValue: null,
                        },
                    };
                }
                return {
                    ...row,
                    isDateFilter,
                    isNumberFilter,
                    attribute: option,
                    operator: { value: '', label: 'Select...' },
                    value: '',
                };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleOperatorChange = (option: any, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                if (
                    row.isDateFilter &&
                    (option.value === 'isBetween' || dateOperatorArray.includes(option.value))
                ) {
                    return {
                        ...row,
                        operator: option,
                        date: { dateValue: null, dateStartValue: null, dateEndValue: null },
                    };
                }
                if (row.isDateFilter && !dateOperatorArray.includes(option.value)) {
                    return {
                        ...row,
                        operator: option,
                        date: {
                            dateValue: new Date().getTime() / 1000,
                            dateStartValue: null,
                            dateEndValue: null,
                        },
                    };
                }
                if (row.isNumberFilter) {
                    return {
                        ...row,
                        operator: option,
                        number: { numberValue: null, numberStartValue: null, numberEndValue: null },
                    };
                }
                return { ...row, operator: option };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleValueChange = (e: React.FormEvent<HTMLInputElement>, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return { ...row, value: e.currentTarget.value };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleNumberChange = (e: React.FormEvent<HTMLInputElement>, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return {
                    ...row,
                    number: {
                        numberValue: Number(e.currentTarget.value),
                        numberStartValue: null,
                        numberEndValue: null,
                    },
                };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleStartNumberChange = (e: React.FormEvent<HTMLInputElement>, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const numberObj = {
                    ...row.number,
                    numberValue: null,
                    numberStartValue: Number(e.currentTarget.value),
                };
                return { ...row, number: numberObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleEndNumberChange = (e: React.FormEvent<HTMLInputElement>, rowNum: number) => {
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const numberObj = {
                    ...row.number,
                    numberValue: null,
                    numberEndValue: Number(e.currentTarget.value),
                };
                return { ...row, number: numberObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleDateChange = (chosenDate: Date | null, rowNum: number) => {
        const changedDate = (chosenDate && chosenDate?.getTime() / 1000) || null;
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                return {
                    ...row,
                    date: { dateValue: changedDate, dateStartValue: null, dateEndValue: null },
                };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleStartDateChange = (chosenDate: Date | null, rowNum: number) => {
        const changedDate = (chosenDate && chosenDate?.getTime() / 1000) || null;
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const dateObj = { ...row.date, dateValue: null, dateStartValue: changedDate };
                return { ...row, date: dateObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleEndDateChange = (chosenDate: Date | null, rowNum: number) => {
        const changedDate = (chosenDate && chosenDate?.getTime() / 1000) || null;
        const updatedFilterRows = filterRows.map((row) => {
            if (row.id === rowNum) {
                const dateObj = { ...row.date, dateValue: null, dateEndValue: changedDate };
                return { ...row, date: dateObj };
            }
            return row;
        });
        setFilterRows(updatedFilterRows);
    };

    const handleAddFilter = () => {
        if (filterRows.length < 7) {
            const newRow = { ...defaultFilterRow, id: filterRows[filterRows.length - 1].id + 1 };
            setFilterRows([...filterRows, newRow]);
        }
    };

    const handleDeleteFilter = (rowNum: number) => {
        const updatedFilterRows = filterRows.filter((fr) => fr.id !== rowNum);
        setFilterRows(updatedFilterRows);
        setSearchedFilters(updatedFilterRows);
    };

    const formatDate = (date: Date | null) => {
        const dateValue =
            date && typeof date.getMonth === 'function' ? date.getTime() / 1000 : null;
        return dateValue;
    };

    const searchHandler = () => {
        const paramArr: {
            attribute: string;
            operator: string;
            attributeValue: string;
            dateValue: number | null;
            dateStartValue: number | null;
            dateEndValue: number | null;
            numberValue: number | null;
            numberStartValue: number | null;
            numberEndValue: number | null;
        }[] = [];
        let isValidParams = true;

        filterRows.forEach((row) => {
            const {
                attribute,
                operator,
                value,
                isDateFilter,
                isNumberFilter,
                date: { dateValue, dateStartValue, dateEndValue },
                number: { numberValue, numberStartValue, numberEndValue },
            } = row;
            if (!isDateFilter && !isNumberFilter) {
                if (attribute.value === '' || operator.value === '' || value === '')
                    isValidParams = false;
            }
            if (isDateFilter) {
                if (operator.value === '') {
                    isValidParams = false;
                } else if (
                    operator.value === 'isBetween' &&
                    (dateStartValue === null || dateEndValue === null)
                ) {
                    isValidParams = false;
                } else if (
                    operator.value !== 'isBetween' &&
                    !dateOperatorArray.includes(operator.value) &&
                    dateValue === null
                ) {
                    isValidParams = false;
                }
            }
            if (isNumberFilter) {
                if (operator.value === '') {
                    isValidParams = false;
                } else if (
                    operator.value === 'isBetween' &&
                    (numberStartValue === null || numberEndValue === null)
                ) {
                    isValidParams = false;
                } else if (operator.value !== 'isBetween' && numberValue === null) {
                    isValidParams = false;
                }
            }
            const paramObj = {
                attribute: attribute?.value || '',
                operator: operator?.value || '',
                attributeValue: value || '',
                dateValue: dateValue || null,
                dateStartValue: dateStartValue || null,
                dateEndValue: dateEndValue || null,
                numberValue: typeof numberValue === 'number' ? numberValue : null,
                numberStartValue: typeof numberStartValue === 'number' ? numberStartValue : null,
                numberEndValue: typeof numberEndValue === 'number' ? numberEndValue : null,
            };
            paramArr.push(paramObj);
        });

        if (isValidParams) {
            setShowFilters(false);
            setFilterParams(paramArr);
            setExportFilters(paramArr);
            setSearchedFilters(filterRows);
        } else {
            toast.error('Enter all filter values');
        }
    };

    useEffect(() => {
        if (searchButtonClicked) {
            searchHandler();
        }
    }, [searchButtonClicked]);

    const fetchCustomReportData = async (val: number) => {
        if (val) {
            const res = await getCustomerReportById(val);
            if (res?.apiStatus === 'SUCCESS' && res.customReport.filters) {
                setFilterRows(res.customReport.filters);
                setCustomReportData({
                    title: res.customReport.title,
                    description: res.customReport.description,
                });
                setSearchButtonClicked(true);
            }
        }
    };
    useEffect(() => {
        if (reportId && search.includes('CUSTOM_REPORT')) {
            fetchCustomReportData(Number(reportId));
        }
    }, [reportId]);

    // eslint-disable-next-line max-lines-per-function
    const FiltersRow: React.FC<FilterProps> = ({ row }) => {
        const {
            id,
            attribute,
            operator,
            value,
            isDateFilter,
            isNumberFilter,
            date: { dateValue, dateStartValue, dateEndValue },
            number: { numberValue, numberStartValue, numberEndValue },
        } = row;
        return (
            <div className="filter-row" id={id.toString()}>
                <div className="filter filter-right-margin">
                    <label htmlFor={`attributeFilter_${id}`}>Attribute</label>
                    <Select
                        name={`attributeFilter_${id}`}
                        id={`attributeFilter_${id}`}
                        className="react-select"
                        classNamePrefix="select"
                        options={attributeOption}
                        onChange={(e) => handleAttributeChange(e, id)}
                        value={attribute}
                    />
                </div>
                <div className="filter filter-right-margin">
                    <label htmlFor={`operatorFilter_${id}`}>Operator</label>
                    <Select
                        name={`operatorFilter_${id}`}
                        id={`operatorFilter_${id}`}
                        className="react-select"
                        classNamePrefix="select"
                        options={(() => {
                            if (isDateFilter) {
                                return operatorOption[1];
                            }
                            if (isNumberFilter) {
                                return operatorOption[2];
                            }
                            return operatorOption[0];
                        })()}
                        onChange={(e) => handleOperatorChange(e, id)}
                        value={operator}
                    />
                </div>
                {!isDateFilter && !isNumberFilter && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`filterValue_${id}`}>Value</label>
                        <input
                            name="filterValue"
                            id={`filterValue_${id}`}
                            key={`filterValue_${id}`}
                            defaultValue={value}
                            onBlur={(e) => handleValueChange(e, id)}
                            placeholder="Enter Value"
                            className="input-value"
                        />
                    </div>
                )}
                {isNumberFilter && !(operator.value === 'isBetween') && (
                    <div className="filter filter-right-margin">
                        <label htmlFor={`filterValue_${id}`}>Value</label>
                        <input
                            name="filterValue"
                            id={`filterValue_${id}`}
                            type="number"
                            key={`filterValue_${id}`}
                            defaultValue={typeof numberValue === 'number' ? numberValue : undefined}
                            onBlur={(e) => handleNumberChange(e, id)}
                            placeholder="Enter Number"
                            className="input-value"
                        />
                    </div>
                )}
                {isNumberFilter && operator.value === 'isBetween' && (
                    <>
                        <div className="filter">
                            <label htmlFor={`filterStartNumberValue_${id}`}>Value</label>
                            <input
                                name="filterStartNumberValue"
                                id={`filterStartNumberValue_${id}`}
                                type="number"
                                key={`filterStartNumberValue_${id}`}
                                defaultValue={
                                    typeof numberStartValue === 'number'
                                        ? numberStartValue
                                        : undefined
                                }
                                onBlur={(e) => handleStartNumberChange(e, id)}
                                className="input-value"
                                placeholder="Start Number"
                            />
                        </div>
                        <div className="filter-dash-margin">&nbsp;-&nbsp;</div>
                        <div className="filter filter-margin filter-right-margin">
                            <input
                                name="filterEndNumberValue"
                                id={`filterEndNumberValue_${id}`}
                                type="number"
                                key={`filterEndNumberValue_${id}`}
                                defaultValue={
                                    typeof numberEndValue === 'number' ? numberEndValue : undefined
                                }
                                onBlur={(e) => handleEndNumberChange(e, id)}
                                className="input-value"
                                placeholder="End Number"
                            />
                        </div>
                    </>
                )}
                {isDateFilter &&
                    !(operator.value === 'isBetween') &&
                    !dateOperatorArray.includes(operator.value) && (
                        <div className="filter filter-right-margin">
                            <label htmlFor={`filterDateValue_${id}`}>Value</label>
                            <DatePicker
                                name="filterDateValue"
                                id={`filterDateValue_${id}`}
                                selected={dateValue ? new Date(dateValue * 1000) : null}
                                onChange={(date: Date | null) => handleDateChange(date, id)}
                                className="input-value"
                            />
                        </div>
                    )}
                {isDateFilter && operator.value === 'isBetween' && (
                    <>
                        <div className="filter">
                            <label htmlFor={`filterStartDateValue_${id}`}>Value</label>
                            <DatePicker
                                name="filterStartDateValue"
                                id={`filterStartDateValue_${id}`}
                                selected={dateStartValue ? new Date(dateStartValue * 1000) : null}
                                onChange={(date: Date | null) => handleStartDateChange(date, id)}
                                className="input-value"
                                placeholderText="Start Date"
                            />
                        </div>
                        <div className="filter-dash-margin">&nbsp;-&nbsp;</div>
                        <div className="filter filter-margin filter-right-margin">
                            <DatePicker
                                name="filterEndDateValue"
                                id={`filterEndDateValue_${id}`}
                                selected={dateEndValue ? new Date(dateEndValue * 1000) : null}
                                onChange={(date: Date | null) => handleEndDateChange(date, id)}
                                className="input-value"
                                placeholderText="End Date"
                            />
                        </div>
                    </>
                )}
                <div className="delete-filter">
                    {filterRows.length > 1 && (
                        <DeleteSvg
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDeleteFilter(id)}
                        />
                    )}
                </div>
            </div>
        );
    };

    const handleSaveFilter = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        if (!search.includes('report_id')) {
            setCustomReportData({
                title: '',
                description: '',
            });
        }
    };

    const SaveCustomReportHandler = async () => {
        setSaveFilterLoading(true);
        const dataObj: any = {};
        dataObj.title = customReportData.title;
        dataObj.description = customReportData.description;
        dataObj.type = 'custom';
        dataObj.filters = searchedFilters;
        if (search.includes('report_id')) {
            const response = await updateCustomReport(
                Number(reportId),
                dataObj.title,
                dataObj.description,
                dataObj.filters,
            );
            if (response?.apiStatus === 'SUCCESS') {
                setSaveFilterLoading(false);
                toast.success('Custom Report Updated Successfully');
                navigate(`/reportList`);
            } else {
                toast.error(response?.errorMessage || 'Unable to Update Custom Report');
                setSaveFilterLoading(false);
            }
        } else {
            const response = await createCustomReport(dataObj);
            if (response?.apiStatus === 'SUCCESS') {
                setSaveFilterLoading(false);
                toast.success('Custom Report Created Successfully');
                navigate(`/reportList`);
            } else {
                toast.error(response?.errorMessage || 'Unable to Create Custom Report');
                setSaveFilterLoading(false);
            }
        }
    };

    const exportHandler = async () => {
        setExportReportLoading(true);
        const params: any = {
            sortBy: 'createdAt',
            sortOrder: 'DESC',
            filters: JSON.stringify(exportFilters),
        };
        if (reportName) {
            params.reportName = reportName;
        }
        if (reportId) {
            params.reportId = reportId;
        }
        toast.info(
            'Your Report Run is being processed. Results will be available to download in Report Summary after some time.',
        );
        setTimeout(() => {
            toast.success('Redirecting to Report Summary.')
            navigate(`/reportList/detail-summary?report_id=${reportId}&report_name=${reportName}`);
        }, 2000);
        await requestExcelGeneration(params);
        setExportReportLoading(false);
    };

    return (
        <div className="contract-table-filters">
            {showFilters && (
                <>
                    {filterRows.map((filterRow) => (
                        <FiltersRow key={filterRow.id} row={filterRow} />
                    ))}
                    <div className="filter-buttons">
                        {showSaveFilter && (
                            <div className="button-div filter-right-margin">
                                <button
                                    type="button"
                                    onClick={handleSaveFilter}
                                    disabled={!(searchedFilters && searchedFilters.length > 0)}
                                >
                                    Save Report
                                </button>
                            </div>
                        )}
                        {/* <div className="button-div filter-right-margin">
                            <button
                                type="button"
                                onClick={() => setShowReportModal(true)}
                            >
                                Duplicate Report
                            </button>
                        </div> */}
                        <div className="button-div filter-right-margin">
                            <button
                                type="button"
                                onClick={handleAddFilter}
                                disabled={filterRows.length > 6}
                            >
                                Add Filter
                            </button>
                        </div>
                        {/* <div className="button-div filter-right-margin">
                            <button
                                type="button"
                                className="submit-button"
                                onClick={exportHandler}
                                disabled={!reportId}
                                title={
                                    reportId ? '' : 'Save the report to enable Run Report button'
                                }
                            >
                                <span className="submit">Run Report</span>
                                {exportReportLoading && (
                                    <Loader
                                        type="Oval"
                                        color={CONSTANTS.BRAND_COLOR}
                                        height={14}
                                        width={14}
                                    />
                                )}
                            </button>
                        </div> */}
                        <div className="button-div">
                            <button type="button" onClick={searchHandler}>
                                Preview
                            </button>
                        </div>
                    </div>
                </>
            )}

            {!showFilters && !!searchedFilters.length && (
                <div className="filter-summary">
                    <h4>Searched for: </h4>
                    {searchedFilters.map((row, i) => {
                        let dateString = '';
                        if (
                            row.date.dateValue ||
                            row.date.dateStartValue ||
                            row.date.dateEndValue
                        ) {
                            dateString =
                                row?.date?.dateStartValue && row?.date?.dateEndValue
                                    ? `${new Date(
                                        row?.date?.dateStartValue * 1000,
                                    ).toDateString()} - ${new Date(
                                        row?.date?.dateEndValue * 1000,
                                    ).toDateString()}`
                                    : `${(row?.date?.dateValue &&
                                        new Date(
                                            row?.date?.dateValue * 1000,
                                        ).toDateString()) ||
                                    ''
                                    }`;
                        }
                        const numberString = (() => {
                            if (
                                row?.number?.numberStartValue !== undefined &&
                                row?.number?.numberStartValue !== null
                            ) {
                                return `${row?.number?.numberStartValue} - ${row?.number?.numberEndValue}`;
                            }
                            if (
                                row?.number?.numberValue !== undefined &&
                                row?.number?.numberValue !== null
                            ) {
                                return `${row?.number?.numberValue}`;
                            }
                            return null;
                        })();
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <span key={i}>
                                {(() => {
                                    switch (true) {
                                        case row.isDateFilter:
                                            return ` ${row?.attribute?.label
                                                } ${row?.operator?.label?.toLowerCase()} ${dateString} `;
                                        case row.isNumberFilter:
                                            return ` ${row?.attribute?.label
                                                } ${row?.operator?.label?.toLowerCase()} ${numberString} `;
                                        default:
                                            return ` ${row?.attribute?.label
                                                } ${row?.operator?.label?.toLowerCase()} '${row?.value
                                                }' `;
                                    }
                                })()}
                            </span>
                        );
                    })}
                </div>
            )}

            <div className="filter-arrow" title="Click to show / hide filters">
                {showFilters ? (
                    <ChevronDoubleUp onClick={() => setShowFilters(false)} />
                ) : (
                    <ChevronDoubleDown onClick={() => setShowFilters(true)} />
                )}
            </div>
            <Modal isOpen={showModal} onRequestClose={closeModal} className="save-filter">
                <PlusIconSvg className="close-btn" onClick={closeModal} />
                <div className="description-heading">
                    <h1>Custom Report</h1>
                </div>
                <div className="description-section">
                    <label className="mandatory-field">Title</label>
                    <input
                        type="text"
                        name="title"
                        className="description-value"
                        placeholder="Enter Title ..."
                        value={customReportData.title}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="description-section">
                    <label>Description</label>
                    <input
                        type="text"
                        name="description"
                        className="description-value"
                        placeholder="Enter Description ..."
                        value={customReportData.description}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="action-buttons">
                    <button type="submit" onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="primary-btn submit-button"
                        onClick={SaveCustomReportHandler}
                        disabled={!customReportData.title}
                    >
                        <span className="submit">Save</span>
                        {saveFilterLoading && (
                            <Oval
                                color={CONSTANTS.BRAND_COLOR}
                                height={14}
                                width={14}
                            />
                        )}
                    </button>
                </div>
            </Modal>
            <CustomReportModal isModalOpen={showReportModal} onModalClose={setShowReportModal} />
        </div>
    );
};

export default ReportsTableFilters;