/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-danger */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getMilliseconds } from 'date-fns';

import './SearchCard.scss';
import { ISearch } from '../../interfaces/dashboard';
import CONSTANTS from '../../constants';
import { returnformatDateOnly } from '../../utils/date';
import { FavoriteComponent } from '../General';
import CopyText from '../CopyText';
import { SkeletonText } from '../Skeleton';
import useWindowSize from '../../hooks/use-window-size';

const WIDTH_OF_SIDEBAR_AND_MARGINS = 450;

interface Props {
    searchDocument: ISearch;
    favoriteDocIds: number[];
    fetchFavoriteDocIds: () => void;
    docTypeColor?: string;
}

// eslint-disable-next-line max-lines-per-function
const ConfidentialSearchCard: React.FC<Props> = ({ searchDocument, favoriteDocIds, fetchFavoriteDocIds, docTypeColor }) => {
    const navigate = useNavigate();
    const windowSize = useWindowSize();
    const rightPartWidth = document.getElementById('header-right-part')?.offsetWidth as number;


    return (
        <div className="search-card">
            <div className="search-card__header" id="search-card__header">
                <div className="header-title">
                    <Link
                        className="link-style"
                        style={{
                            textDecoration: 'none',
                            maxWidth: `${windowSize.width - rightPartWidth - WIDTH_OF_SIDEBAR_AND_MARGINS
                                }px`,
                            color: '#f05b13',
                            fontSize: '18px',
                        }}
                        to={{
                            pathname: `/${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                            search: `?doc_id=${searchDocument.docId}&prev_page_type=SEARCH`,
                        }}
                        role="presentation"
                    >
                        <SkeletonText text="Confidential: Authorized Users Only" width={60} />
                    </Link>
                </div>
                <div className="header-right-part" id="header-right-part">
                    <div className="fav-copy">
                        <CopyText
                            text={searchDocument.docNumber}
                            docTypeBackgroundColor={docTypeColor}
                        />
                    </div>

                    <FavoriteComponent
                        docId={searchDocument.docId}
                        isActive={!!favoriteDocIds.includes(searchDocument.docId)}
                        onClickHandler={fetchFavoriteDocIds}
                    />
                </div>
            </div>
            <div className="search-card__info">
                <div className="card-info-item">
                    <label htmlFor="version">Version</label>
                    <h4>
                        <SkeletonText text={searchDocument.version} width={60} />
                    </h4>
                </div>
                <div className="card-info-item">
                    {getMilliseconds(searchDocument.createdDate) === 1 ? (
                        <>
                            <label htmlFor="approvedOn">Published on</label>
                            <h4>
                                <Skeleton width={100} />
                            </h4>
                        </>
                    ) : (
                        <>
                            <label htmlFor="version">Published on</label>
                            <h4>
                                <SkeletonText
                                    text={returnformatDateOnly(searchDocument.createdDate)}
                                    width={60}
                                />
                            </h4>
                        </>
                    )}
                </div>
                <div className="card-info-item">
                    <label htmlFor="version">Author</label>
                    <h4>
                        <SkeletonText text={searchDocument.authorName} width={60} />
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default ConfidentialSearchCard;
