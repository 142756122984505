import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import { getAllCustomReports, getAllStandardReports } from '../../API/reports';
import CustomReportListTablePage from '../../components/reports/CustomReportListTablePage';
import StandardReportListTablePage from '../../components/reports/StandardReportListTablePage';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { IPaginationData } from '../../interfaces';
import { IReportsList } from '../../interfaces/reports';
import CustomReportModal from '../../widgets/reports/CustomReportModal';
import './ReportsListPage.scss';

const { REPORT_LIST } = STATIC_CONTENT;

// eslint-disable-next-line max-lines-per-function
const ReportsListPage: React.FC = () => {
    const navigate = useNavigate();
    const defaultReportsListData = () => {
        const reportsListData: IReportsList[] = [];
        Array.from({ length: 5 }).forEach(() => {
            reportsListData.push({
                creator: CONSTANTS.LOADING.TEXT,
                title: CONSTANTS.LOADING.TEXT,
                description: CONSTANTS.LOADING.TEXT,
                id: CONSTANTS.LOADING.NUMBER,
                type: CONSTANTS.LOADING.TEXT,
                createdAt: CONSTANTS.LOADING.DATE,
                visibility: CONSTANTS.LOADING.TEXT,
            });
        });
        return reportsListData;
    };
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
    };
    const [activeTab, setActiveTab] = useState('Custom');
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [data, setData] = useState(defaultReportsListData);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        if (activeTab === '') {
            setActiveTab('Custom');
        }
    }, []);
    const update = async (
        pageIndex?: number,
        pageSize?: number,
        order?: string,
        orderType?: string | undefined,
    ) => {
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const sortBy = order || 'createdAt';
        const sortOrder = orderType || 'DESC';
        const params = {
            limit: size,
            offset: index * size,
            sortBy,
            sortOrder,
        };
        if (activeTab === 'Custom') {
            const reports = await getAllCustomReports(qs.stringify(params));
            if (reports?.apiStatus === 'SUCCESS') {
                setData(reports.customReports);
                setPagination({
                    pageSize: size,
                    pageIndex: index,
                    totalCount: reports.total,
                });
            }
        } else if (activeTab === 'Standard') {
            const reports = await getAllStandardReports(qs.stringify(params));
            if (reports?.apiStatus === 'SUCCESS') {
                setData(reports.standardReports);
                setPagination({
                    pageSize: size,
                    pageIndex: index,
                    totalCount: reports.total,
                });
            }
        }
    };
    useEffect(() => {
        if (activeTab === 'Custom' || activeTab === 'Standard') {
            update(0, 10, 'createdAt', 'DESC');
        }
    }, [activeTab]);
    const createReport = () => {
        navigate(`/reportList/report?prev_page_type=CUSTOM_REPORT`);
    };
    const tabChangeHandler = (type: string) => {
        setActiveTab(type);
        setData(defaultReportsListData);
    };
    return (
        <>
            {activeTab ? (
                <div className="reports-tab tab">
                    <button
                        id="Custom"
                        type="button"
                        aria-label="Custom Reports"
                        className={`${activeTab === 'Custom' ? 'active' : ''}`}
                        onClick={() => tabChangeHandler('Custom')}
                    >
                        <span className="name">{REPORT_LIST.TABS.CUSTOM}</span>
                    </button>
                    <button
                        id="Standard"
                        type="button"
                        aria-label="Standard Reports"
                        className={`${activeTab === 'Standard' ? 'active' : ''}`}
                        onClick={() => tabChangeHandler('Standard')}
                    >
                        <span className="name">{REPORT_LIST.TABS.STANDARD}</span>
                    </button>
                </div>
            ) : (
                <div className="puff-loader">
                    <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
                </div>
            )}
            <div className="reports">
                {activeTab === 'Custom' ? (
                    <>
                        <div className="create-new-report">
                            <button
                                type="button"
                                className="primary-btn"
                                onClick={createReport}
                            >
                                {REPORT_LIST.BUTTON.CREATE_NEW}
                            </button>
                        </div>
                        <CustomReportListTablePage
                            data={data}
                            pagination={pagination}
                            fetchReports={(
                                pageIndex: number,
                                pageSize: number,
                                order: string,
                                orderType: string | undefined,
                            ) => update(pageIndex, pageSize, order, orderType)}
                        />
                        <CustomReportModal
                            isModalOpen={showModal}
                            onModalClose={setShowModal}
                        />
                    </>
                ) : (
                    <StandardReportListTablePage
                        data={data}
                        pagination={pagination}
                        fetchReports={(
                            pageIndex: number,
                            pageSize: number,
                            order: string,
                            orderType: string | undefined,
                        ) => update(pageIndex, pageSize, order, orderType)}
                    />
                )}
            </div>
        </>
    );
};

export default ReportsListPage;