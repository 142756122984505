/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import PuffLoader from "react-spinners/PuffLoader";
import { getFromLocalStorage, removeFromLocalStorage } from '../../utils/local-storage';
import CONSTANTS from '../../constants';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { setInMemory } from '../../utils/in-memory-storage';

// eslint-disable-next-line max-lines-per-function
const CodeVerify: React.FC = () => {
    const [status, setStatus] = useState(false);
    const { search } = useLocation();
    const { onLogin } = useAuthDataContext();
    const PKCEAuthCodeSecondStep = async (code: string) => {
        const oidcURL = `${process.env.REACT_APP_OIDC_URL}/token `;
        const queryParams = {
            grant_type: 'authorization_code',
            redirect_uri: `${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.callback.WPUrl}`,
            client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
            code_verifier: getFromLocalStorage('code_verifier'),
            code,
        };

        const params = qs.stringify(queryParams);

        removeFromLocalStorage('code_verifier');
        return axios
            .post(oidcURL, params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then((res: { data: { access_token: string; id_token: string } }) => {
                setInMemory(res.data.id_token);
                setStatus(true);
                return res.data;
            })
            .catch((error) => {
                if (error.response) {
                    console.error(error.response.data);
                }
            });
    };

    const handleRequest = async () => {
        try {
            const urlParams = new URLSearchParams(search);
            const code = urlParams.get('code');
            if (code) {
                await PKCEAuthCodeSecondStep(code);
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setStatus(false);
        handleRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (status) {
        onLogin();
        const path = getFromLocalStorage('path')
            ? (getFromLocalStorage('path') as string)
            : '/dashboard';
        return <Navigate to={path} />;
    }

    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

export default CodeVerify;
