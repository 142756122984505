import React from 'react';
import {Tooltip} from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import 'react-popper-tooltip/dist/styles.css';

import { IDocReferenceInfo, IField, IFieldsData } from '../../interfaces/document';
import { ITravelForm, IUiControlTypes } from '../../interfaces';
import {
    Text,
    SingleSelect,
    TextBox,
    Number,
    DatePicker,
    MultiSelectDropdown,
    TextBoxWithChip,
    DocumentReference,
    Float,
} from '../input-fields';
import ToggleInput from '../input-fields/toggle/ToggleInput';
import './ImportantFields.scss';
import { IDocumentAction } from '../../reducers/document-reducer';
import ButtonSelector from '../input-fields/button-selector/ButtonSelector';
import { InvalidSvg } from '../../assets/images';
import Markup from '../input-fields/markup/Markup';
import LinkedDocuments from '../input-fields/linked-documents/LinkedDocuments';
import DatePickerDateOnly from '../input-fields/date-picker-date-only/DatePickerDateOnly';
import { useDocumentContext } from '../../contexts/document-provider';
import TravelForm from '../input-fields/travel-form/TravelForm';
import STATIC_CONTENT from '../../constants/StaticContent';

const intialTripDetails = [{ from: '', to: '', fromDate: '', toDate: '' }];

const initialTravelForm: ITravelForm = {
    travelDetails: intialTripDetails,
    travelType: STATIC_CONTENT.TRAVEL_FORM.ROUND_TRIP,
};

interface IInputComponentProps {
    componentType: IUiControlTypes;
    isDisabled: boolean;
    field: IField;
    documentId: number;
    documentVersionId: number;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
}

// eslint-disable-next-line max-lines-per-function
const InputComponent: React.FC<IInputComponentProps> = ({
    componentType,
    isDisabled,
    field,
    documentId,
    documentVersionId,
    fieldsData,
    documentDispatch,
    errorFields,
}: IInputComponentProps) => {
    const { accessibleByCurrentUser } = useDocumentContext();
    const [fieldData] = fieldsData.filter((a) => a.name === field.name);
    switch (componentType) {
        case 'Text':
            return (
                <Text
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Text Box':
            return (
                <TextBox
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Single Select Dropdown':
            return (
                <SingleSelect
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={
                        isDisabled ||
                        field.name === 'contractType' ||
                        field.name === 'subCategory' ||
                        field.name === 'subCategory2' ||
                        (accessibleByCurrentUser ? false : field.readOnly)
                    }
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Multi Select Dropdown':
            return (
                <MultiSelectDropdown
                    documentDispatch={documentDispatch || null}
                    field={field}
                    documentId={documentId}
                    documentVersionId={documentVersionId}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Number':
            return (
                <Number
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    value={fieldData?.value ? (fieldData.value as number) : 0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Float':
            return (
                <Float
                    documentDispatch={documentDispatch || null}
                    field={field}
                    step={field.step}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as number) : 0.0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'DatePicker':
            return (
                <DatePicker
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    value={fieldData?.value ? new Date(fieldData.value as Date) : new Date()}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'OnlyDate':
            return (
                <DatePickerDateOnly
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    value={fieldData?.value ? fieldData.value : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Text Box With Chip':
            return (
                <TextBoxWithChip
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled || (accessibleByCurrentUser ? false : field.readOnly)}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Toggle':
            return (
                <ToggleInput
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as boolean) : false}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Button Select':
            return (
                <ButtonSelector
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Markup':
            return <Markup field={field} />;
        case 'Table':
            return (
                <DocumentReference
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as IDocReferenceInfo[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Reference':
            return (
                <LinkedDocuments
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={false}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                    isEditable={documentDispatch !== null}
                    isChild={false}
                />
            );
        case 'Travel Form':
            return (
                <TravelForm
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as ITravelForm) : initialTravelForm}
                    error={errorFields.includes(field.name)}
                />
            );
        default:
            return <div>something is wrong</div>;
    }
};

interface FieldsToolTipProps {
    isDisabled: boolean;
    field: IField;
    documentId: number;
    documentVersionId: number;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
}

// eslint-disable-next-line max-lines-per-function
const FieldsToolTip = ({
    isDisabled,
    field,
    documentId,
    documentVersionId,
    fieldsData,
    documentDispatch,
    errorFields,
}: FieldsToolTipProps) => {
    const halfUiControl: IUiControlTypes[] = [
        'DatePicker',
        'OnlyDate',
        'Number',
        'Single Select Dropdown',
        'Toggle',
    ];
    const { dateErrorInfo, isTravelAuthorizationForm, isItACustomaerMeeting, travelRequestFor } = useDocumentContext();
    if (isTravelAuthorizationForm) {
        if (field.name === 'customerName' && !isItACustomaerMeeting) {
            return (
                <></>
            )
        }
        if (field.name === 'guestName' && travelRequestFor.value !== 'Guest') {
            return (
                <></>
            )
        }
        if (field.name === 'traveller' && travelRequestFor.value !== 'Other Employee') {
            return (
                <></>
            )
        }

    }
    let displayField = true;
    if (field.name === 'businessPurposeDescription') {
        const [toggleField] = fieldsData.filter((a) => a.name === 'specificBusinessPurpose');
        displayField = toggleField.value;
    }
    return (
        displayField ? 
        <>
            <div
                key={field.name}
                className={`${halfUiControl.includes(field.uiControl.label) ? 'half' : 'full'}`}
            >
                {field.markupText === '' && (
                    <label
                        htmlFor={field.name}
                        className={field.mandatory ? 'mandatory-field' : ''}
                        style={{
                            display:
                                field.name !== 'LinkedDocuments' &&
                                    (field.dataType.value !== 7 || field.displayName !== '.')
                                    ? 'block'
                                    : 'none',
                        }}
                    >
                        {field.displayName}
                    </label>
                )}
                <div data-for="input-component" data-tip={!isDisabled ? field.helperText : null}>
                    <>
                        <InputComponent
                            componentType={field.uiControl.label}
                            isDisabled={isDisabled}
                            field={field}
                            documentId={documentId}
                            documentVersionId={documentVersionId}
                            documentDispatch={documentDispatch}
                            fieldsData={fieldsData}
                            errorFields={errorFields || []}
                        />
                        {errorFields.includes(field.name) && (
                            <span className="error-message">
                                <InvalidSvg /> This field is mandatory
                            </span>
                        )}
                        {dateErrorInfo.errorFields.includes(field.name) && (
                            <span className="error-message">
                                <InvalidSvg /> Date is Invalid
                            </span>
                        )}
                    </>
                    <Tooltip id="input-component" place="left"/>
                </div>
            </div>
        </> : null
    );
};

interface Props {
    isDisabled: boolean;
    fields: IField[];
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    // eslint-disable-next-line react/require-default-props
    errorFields?: string[];
    // eslint-disable-next-line react/require-default-props
    documentId?: number;
    // eslint-disable-next-line react/require-default-props
    documentVersionId?: number;
}

const SkeletonImportantFields = (): JSX.Element => (
    <div className="full">
        <label htmlFor="">
            <Skeleton />
        </label>
        <div>
            <Skeleton width="80%" />
        </div>
    </div>
);

// eslint-disable-next-line max-lines-per-function
const ImportantFields: React.FC<Props> = ({
    isDisabled,
    fields,
    documentId,
    documentVersionId,
    fieldsData,
    documentDispatch,
    errorFields,
}: Props) => (
    <div className="important-fields">
        {fields.length > 0 ? (
            fields.map((field) => (
                <FieldsToolTip
                    key={field.id}
                    isDisabled={isDisabled}
                    field={field}
                    documentId={documentId || 0}
                    documentVersionId={documentVersionId || 0}
                    documentDispatch={documentDispatch}
                    fieldsData={fieldsData}
                    errorFields={errorFields || []}
                />
            ))
        ) : (
            <>
                <SkeletonImportantFields />
                <SkeletonImportantFields />
                <SkeletonImportantFields />
            </>
        )}
    </div>
);

export default ImportantFields;
