import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

export const ItemTypes = {
    CARD: 'card',
};

export interface CardDragObject {
    id: string;
    index: number;
}

export interface CardProps {
    id: any;
    index: number;
    children: React.ReactNode;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}

const ReactDND = forwardRef<HTMLDivElement, CardProps>(
    // eslint-disable-next-line max-lines-per-function
    ({ id, index, moveCard, children }, ref) => {
        const elementRef = useRef<HTMLDivElement>(null);

        const [{ isDragging }, drag] = useDrag({
            type: ItemTypes.CARD,
            item: { id, index },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const [, drop] = useDrop({
            accept: ItemTypes.CARD,
            hover(item: CardDragObject, monitor) {
                const dragIndex = item.index;
                const hoverIndex = index;
                

                // Don't replace items with themselves
                if (dragIndex === hoverIndex) return;

                const hoverBoundingRect = elementRef.current?.getBoundingClientRect();
                if (!hoverBoundingRect) return;

                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                const clientOffset = monitor.getClientOffset();
                if (!clientOffset) return;

                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                // Only perform the move when the mouse has crossed half of the item's height
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

                // Perform the move
                moveCard(dragIndex, hoverIndex);

                // Mutate the item to avoid expensive index searches
                item.index = hoverIndex;
            },
        });

        const dragDropRef = drag(drop(elementRef));
        const opacity = isDragging ? 0.5 : 1;

        // Expose the ref to the parent component
        useImperativeHandle(ref, () => (
            elementRef.current ? elementRef.current : ({} as HTMLDivElement)
        ));

        return (
            <div ref={elementRef} style={{ opacity }}>
                {children}
            </div>
        );
    },
);
ReactDND.displayName = 'ReactDND';

export default ReactDND;