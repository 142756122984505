/* eslint-disable max-lines-per-function */
import React, { createContext, useContext, useState, useEffect, useReducer, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    getDocTypeVersion,
    updateDocTypeVersion,
    updateDocType,
    createDocumentType,
    publishDocumentType,
    // getDocumentTypeVersions,
} from '../API/document-type';
import { bulkUpdateFields } from '../API/fields';
import {
    IApprovalStage,
    ICardError,
    IDocTypeConfig,
    IDocTypeNumbering,
    IDocTypeVersionError,
    IDocVersionObject,
    IDocumentTypeInfo,
    IGenerationTrigger,
} from '../interfaces/document-type';
import { IField } from '../interfaces/document';
import { useQueryParams } from './query-params-provider';
import { IDocTypeAction, docTypeReducer } from '../reducers/doc-type-reducer';
import {
    IDocNumberingAction,
    docNumberingReducer,
    initialDocNumberingData,
} from '../reducers/doc-numbering-reducer';
import { approvalStageReducer, IApprovalStageAction } from '../reducers/doc-type-stage-reducer';
import { IOptionValue } from '../interfaces';
import CONSTANTS from '../constants';
import {
    docTypeAttachmentConfigReducer,
    IDocTypeAttachmentConfigAction,
} from '../reducers/doc-type-attachment-reducer';
import { IAttachmentConfig } from '../interfaces/attachment';
import STATIC_CONTENT from '../constants/StaticContent';

const defaultInitialVersionValue: IDocVersionObject = {
    majorVersionNumber: 1,
    minorVersionNumber: 0
};

const initialDocTypeInfo: IDocumentTypeInfo = {
    title: '',
    description: '',
    version: '',
    status: 'DRAFT',
    isConfidential: false,
    allowExport: false,
    isDCO: false,
    fullWidthTitleDesc: false,
    singlePageDocCreation: false,
    restrictCommentForPublished: false,
    showCommentsAfterSubmitDate: false,
    allowDCO: false,
    allowObsolete: false,
    allowRerelease: false,
    allowReviseByAnyone: false,
    allowViewingInProcessDocByAnyone: false,
    allowCustomVersioning: false,
    blockRevise: false,
    anyoneCanEditDraft: false,
    enableApprovalDeadline: false,
    defaultApprovalDeadline: 5,
    hideEventTimeline: false,
    storeAndShowActivity: false,
    isContract: false,
    allowTemplateGeneration: false,
    detailsSectionTitle: undefined,
    attachmentSectionTitle: undefined,
    approversSectionTitle: undefined,
    approverTimeStamp: undefined,
    defaultInitialVersion: defaultInitialVersionValue,
    isFileType: false,
    allowFileTypeAttachment: false
};

const initialDocTypeVersionError: IDocTypeVersionError = {
    APPROVAL_FLOW: {
        error: false,
        cards: [],
    },
    ATTACHMENT_FLOW: {
        error: false,
        cards: [],
    },
    DOCUMENT_DETAILS: {
        error: false,
        cards: [],
    },
};

interface IDocumentTypeContext {
    documentTypeId: number;
    docTypeInfo: IDocumentTypeInfo;
    fields: IField[];
    approvalStages: IApprovalStage[];
    attachmentConfig: IAttachmentConfig[];
    docNumbering: IDocTypeNumbering[];
    publishBtnLoading: boolean;
    docTypeVersions: IOptionValue[];
    docTypeConfig: IDocTypeConfig;
    generationTrigger: IGenerationTrigger;
    docTypeErrors: {
        title: boolean;
        description: boolean;
        code: boolean;
        maxRepublishCount: boolean;
    };
    docTypeVersionError: IDocTypeVersionError;
    docTypeDispatch: React.Dispatch<IDocTypeAction>;
    attachmentConfigDispatch: React.Dispatch<IDocTypeAttachmentConfigAction>;
    docNumberDispatch: React.Dispatch<IDocNumberingAction>;
    approvalStageDispatch: React.Dispatch<IApprovalStageAction>;
    onSaveDraft: () => void;
    onSubmit: () => void;
    changeDocTypeInfo: (name: string, value: string | boolean) => void;
    changeMaxRepublishCount: (value: number) => void;
    changeDefaultApprovalDeadline: (value: number) => void;
    setDocTypeConfig: React.Dispatch<React.SetStateAction<IDocTypeConfig>>;
    setGenerationTrigger: React.Dispatch<React.SetStateAction<IGenerationTrigger>>;
}

const { DOC_TYPE_PROVIDER } = STATIC_CONTENT;

const DocTypeContext = createContext<IDocumentTypeContext>({
    documentTypeId: 0,
    docTypeInfo: initialDocTypeInfo,
    fields: [],
    approvalStages: [],
    attachmentConfig: [],
    docNumbering: [],
    publishBtnLoading: false,
    docTypeVersions: [],
    docTypeConfig: {
        isConfidential: false,
        collectComment: false,
        collectCommentRejection: false,
        publishOnceApproved: true,
        isSunsetEnabled: false,
        isEndDateAsked: false,
        isContract: false,
        maxRepublishCount: 0,
        allowExport: false,
        isDCO: false,
        fullWidthTitleDesc: false,
        singlePageDocCreation: false,
        restrictCommentForPublished: false,
        showCommentsAfterSubmitDate: false,
        allowDCO: false,
        allowObsolete: false,
        allowRerelease: false,
        allowReviseByAnyone: false,
        allowViewingInProcessDocByAnyone: false,
        allowCustomVersioning: false,
        defaultInitialVersion: defaultInitialVersionValue,
        blockRevise: false,
        anyoneCanEditDraft: false,
        enableApprovalDeadline: false,
        defaultApprovalDeadline: 5,
        hideEventTimeline: false,
        storeAndShowActivity: false,
        allowTemplateGeneration: false,
        detailsSectionTitle: undefined,
        attachmentSectionTitle: undefined,
        approversSectionTitle: undefined,
        approverTimeStamp: undefined,
        isFileType: false,
        allowFileTypeAttachment: false
    },
    docTypeErrors: {
        title: false,
        description: false,
        code: false,
        maxRepublishCount: false,
    },
    generationTrigger: 'ON_SUBMIT',
    setGenerationTrigger: () => null,
    docTypeVersionError: initialDocTypeVersionError,
    docTypeDispatch: () => null,
    attachmentConfigDispatch: () => null,
    docNumberDispatch: () => null,
    approvalStageDispatch: () => null,
    onSaveDraft: () => null,
    onSubmit: () => null,
    changeDocTypeInfo: () => null,
    changeMaxRepublishCount: () => null,
    setDocTypeConfig: () => null,
    changeDefaultApprovalDeadline: () => null,
});

type DocumentTypeProviderProps = {
    children?: ReactNode;
};

const DocumentTypeProvider: React.FC<DocumentTypeProviderProps> = ({ children }) => {
    const { getQueryParam } = useQueryParams();
    const navigate = useNavigate();
    const [docTypeInfo, setDocTypeInfo] = useState(initialDocTypeInfo);
    const [generationTrigger, setGenerationTrigger] = useState<IGenerationTrigger>('ON_SUBMIT');
    const [fields, docTypeDispatch] = useReducer(docTypeReducer, []);
    const [attachmentConfig, attachmentConfigDispatch] = useReducer(
        docTypeAttachmentConfigReducer,
        [],
    );
    const [docTypeConfig, setDocTypeConfig] = useState<IDocTypeConfig>({
        collectComment: false,
        collectCommentRejection: false,
        isConfidential: false,
        publishOnceApproved: true,
        isSunsetEnabled: false,
        isEndDateAsked: false,
        isContract: false,
        maxRepublishCount: 0,
        allowExport: false,
        isDCO: false,
        fullWidthTitleDesc: false,
        singlePageDocCreation: false,
        restrictCommentForPublished: false,
        showCommentsAfterSubmitDate: false,
        allowDCO: false,
        allowObsolete: false,
        allowRerelease: false,
        allowReviseByAnyone: false,
        allowViewingInProcessDocByAnyone: false,
        allowCustomVersioning: false,
        defaultInitialVersion: defaultInitialVersionValue,
        blockRevise: false,
        anyoneCanEditDraft: false,
        enableApprovalDeadline: false,
        defaultApprovalDeadline: 5,
        hideEventTimeline: false,
        storeAndShowActivity: false,
        allowTemplateGeneration: false,
        detailsSectionTitle: '',
        attachmentSectionTitle: '',
        approversSectionTitle: '',
        approverTimeStamp: undefined,
        isFileType: false,
        allowFileTypeAttachment: false
    });
    const [approvalStages, approvalStageDispatch] = useReducer(approvalStageReducer, []);
    const [docNumbering, docNumberDispatch] = useReducer(
        docNumberingReducer,
        initialDocNumberingData,
    );
    const [publishBtnLoading, setPublishBtnLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [docTypeVersions, setDocTypeVersions] = useState<IOptionValue[]>([]);
    const [docTypeErrors, setDocTypeErrors] = useState({
        title: false,
        description: false,
        code: false,
        maxRepublishCount: false,
        effectiveDuration: false,
        sunsetDuration: false,
    });
    const [docTypeVersionError, setDocTypeVersionError] = useState<IDocTypeVersionError>(
        initialDocTypeVersionError,
    );

    const documentTypeId = Number(getQueryParam('doc_type_id'));
    const documentTypeVersionId = Number(getQueryParam('doc_type_v_id'));

    const fetchDocTypeData = async () => {
        if (documentTypeId && documentTypeVersionId) {
            const response = await getDocTypeVersion(documentTypeId, documentTypeVersionId);
            if (response) {
                setDocTypeInfo(response.docInfo);
                docTypeDispatch({ type: 'update', value: response.fields });
                approvalStageDispatch({ type: 'update', value: response.stages });
                attachmentConfigDispatch({ type: 'update', value: response.attachments });
                setDocTypeConfig(response.docTypeConfig);
                setGenerationTrigger(response.generationTrigger);
                if (response?.docNumbering?.length > 0) {
                    docNumberDispatch({ type: 'update', value: response.docNumbering });
                }
            }
        }
    };

    const changeDocTypeInfo = (name: string, value: string | boolean) => {
        setDocTypeInfo({ ...docTypeInfo, [name]: value });
    };

    const changeMaxRepublishCount = (value: number) => {
        setDocTypeConfig({ ...docTypeConfig, maxRepublishCount: value });
    };

    const changeDefaultApprovalDeadline = (value: number) => {
        setDocTypeConfig({ ...docTypeConfig, defaultApprovalDeadline: value })
    }

    const onSaveDraft = async (showToast = true): Promise<boolean> => {
        let valid = true;
        const errors = { ...docTypeErrors };
        setDocTypeVersionError(initialDocTypeVersionError);
        const [staticType] = docNumbering.filter((n) => n.type === 'static');
        if (!docTypeInfo.title) {
            valid = false;
            errors.title = true;
        } else {
            errors.title = false;
        }
        if (!docTypeInfo.description) {
            valid = false;
            errors.description = true;
        } else {
            errors.description = false;
        }
        if (Number.isNaN(docTypeConfig.maxRepublishCount) && docTypeConfig.isSunsetEnabled) {
            valid = false;
            errors.maxRepublishCount = true;
        } else {
            errors.maxRepublishCount = false;
        }
        if (!staticType.value) {
            valid = false;
            errors.code = true;
        } else {
            errors.code = false;
        }
        if (valid) {
            if (!documentTypeId && !documentTypeVersionId) {
                const res = await createDocumentType(
                    docTypeInfo.title,
                    docTypeInfo.description,
                    generationTrigger,
                    docNumbering,
                    docTypeConfig,
                );
                if (res && res.apiStatus === 'SUCCESS') {
                    await bulkUpdateFields(fields);
                    const status = await updateDocTypeVersion(
                        res.docTypeId,
                        res.docTypeVersionId,
                        fields,
                        approvalStages,
                        attachmentConfig,
                    );
                    if (status && status === 'SUCCESS') {
                        navigate(
                            `/document-type/create?doc_type_id=${res.docTypeId}&doc_type_v_id=${res.docTypeVersionId}`,
                        );
                        if (showToast) {
                            toast.success(DOC_TYPE_PROVIDER.TOAST.SUCCESS_MSG.SUCCESSFUL);
                        }
                    } else {
                        toast.error(DOC_TYPE_PROVIDER.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
                    }
                }
                if (res?.apiStatus === 'FAILURE') {
                    if (res.response.message) {
                        toast.error(res.response.message);
                    }
                }
            } else {
                // TODO : need to change for numbering strategy
                await bulkUpdateFields(fields);
                const docTypeRes = await updateDocType(
                    documentTypeId,
                    docNumbering,
                    generationTrigger,
                    docTypeConfig,
                    docTypeInfo.title,
                    docTypeInfo.description,
                );
                if (docTypeRes === 'SUCCESS') {
                    const status = await updateDocTypeVersion(
                        documentTypeId,
                        documentTypeVersionId,
                        fields,
                        approvalStages,
                        attachmentConfig,
                    );
                    if (status === 'SUCCESS') {
                        fetchDocTypeData();
                        if (showToast) {
                            toast.success(DOC_TYPE_PROVIDER.TOAST.SUCCESS_MSG.SUCCESSFUL);
                        }
                    } else if (status === 'FAILURE') {
                        toast.error(DOC_TYPE_PROVIDER.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
                    }
                } else {
                    errors.code = true;
                    valid = false;
                    toast.error(DOC_TYPE_PROVIDER.TOAST.ERROR_MSG.CODE_ALREADY_EXIST);
                }
            }
        }
        setDocTypeErrors(errors);
        return valid;
    };

    const validateDocumentDetails = (): { valid: boolean; cardErrors: ICardError[] } => {
        let valid = true;
        const cardErrors: ICardError[] = [];
        if (fields.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const field of fields) {
                const cardError: ICardError = {
                    cardError: false,
                    fieldList: [],
                };
                if (!field.displayName) {
                    valid = false;
                    cardError.fieldList.push('displayName');
                    cardError.cardError = true;
                }
                if (!field.name) {
                    valid = false;
                    cardError.fieldList.push('name');
                    cardError.cardError = true;
                }
                if (!field.helperText) {
                    valid = false;
                    cardError.fieldList.push('helper-text');
                    cardError.cardError = true;
                }
                if (
                    field.uiControl.label === 'Single Select Dropdown' ||
                    field.uiControl.label === 'Multi Select Dropdown'
                ) {
                    if (
                        field.selectType === 'static' &&
                        (!field.staticOptionValues || field.staticOptionValues?.data.length === 0)
                    ) {
                        valid = false;
                        cardError.fieldList.push('static-option');
                        cardError.cardError = true;
                    }
                    if (field.selectType === 'dynamic') {
                        if (!field.dynamicOptionValues) {
                            valid = false;
                            cardError.fieldList.push('tableName');
                            cardError.fieldList.push('displayColumn');
                            cardError.fieldList.push('valueColumn');
                            cardError.cardError = true;
                        }
                        if (!field.dynamicOptionValues?.tableName) {
                            valid = false;
                            cardError.fieldList.push('tableName');
                            cardError.cardError = true;
                        }
                        if (!field.dynamicOptionValues?.displayColumn) {
                            valid = false;
                            cardError.fieldList.push('displayColumn');
                            cardError.cardError = true;
                        }
                        if (!field.dynamicOptionValues?.valueColumn) {
                            valid = false;
                            cardError.fieldList.push('valueColumn');
                            cardError.cardError = true;
                        }
                    }
                }
                if (
                    field.uiControl.label === 'Button Select' &&
                    (!field.staticOptionValues || field.staticOptionValues?.data.length === 0)
                ) {
                    valid = false;
                    cardError.fieldList.push('static-option');
                    cardError.cardError = true;
                }
                if (field.uiControl.label === 'Markup' && field.markupText === '') {
                    valid = false;
                    cardError.fieldList.push('markup-text');
                    cardError.cardError = true;
                }
                cardErrors.push(cardError);
            }
        } else {
            valid = false;
        }

        return { valid, cardErrors };
    };

    const validateApprovalFlow = (): { valid: boolean; cardErrors: ICardError[] } => {
        let valid = true;
        const cardErrors: ICardError[] = [];

        // skip approval flow for dco enabled doc types
        if (docTypeConfig.allowDCO || docTypeConfig.isFileType) return { valid, cardErrors };

        if (approvalStages.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const stage of approvalStages) {
                const cardError: ICardError = {
                    cardError: false,
                    fieldList: [],
                };
                if (
                    stage.scopeType === 'roleBased' &&
                    stage.approvalType === 'INDIVIDUAL' &&
                    stage.roleList.length === 0
                ) {
                    valid = false;
                    cardError.fieldList.push('role-list');
                    cardError.cardError = true;
                }
                if (
                    stage.scopeType === 'roleBased' &&
                    stage.approvalType === 'ANYONE' &&
                    !stage.approvalGroups
                ) {
                    valid = false;
                    cardError.fieldList.push('approval-group');
                    cardError.cardError = true;
                }
                // for group based error
                if (
                    stage.scopeType === 'groupBased' &&
                    !stage.approvalGroups
                ) {
                    valid = false;
                    cardError.fieldList.push('approval-group');
                    cardError.cardError = true;
                }
                if (stage.scopeType === 'managementChain' && !stage.depth) {
                    valid = false;
                    cardError.fieldList.push('role-depth');
                    cardError.cardError = true;
                }
                cardErrors.push(cardError);
            }
        } else {
            valid = false;
        }
        return { valid, cardErrors };
    };

    const validateAttachmentConfig = (): { valid: boolean; cardErrors: ICardError[] } => {
        let valid = true;
        const cardErrors: ICardError[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const attachment of attachmentConfig) {
            const cardError: ICardError = {
                cardError: false,
                fieldList: [],
            };
            if (!attachment.displayName) {
                valid = false;
                cardError.fieldList.push('displayName');
                cardError.cardError = true;
            }
            if (!attachment.attachmentType) {
                valid = false;
                cardError.fieldList.push('attachmentType');
                cardError.cardError = true;
            }
            cardErrors.push(cardError);
        }

        return { valid, cardErrors };
    };

    const onSubmit = async () => {
        let valid = true;
        setPublishBtnLoading(true);
        const docTypeValid = await onSaveDraft(false);
        if (!docTypeValid) {
            valid = false;
        }

        const errorData = initialDocTypeVersionError;
        const docDetails = validateDocumentDetails();
        errorData.DOCUMENT_DETAILS = { error: !docDetails.valid, cards: docDetails.cardErrors };
        if (!docDetails.valid) {
            valid = false;
        }
        const attachmentConfigValidation = validateAttachmentConfig();
        errorData.ATTACHMENT_FLOW = {
            error: !attachmentConfigValidation.valid,
            cards: attachmentConfigValidation.cardErrors,
        };
        if (!attachmentConfigValidation.valid) {
            valid = false;
        }
        const approvalFlowValidation = validateApprovalFlow();
        errorData.APPROVAL_FLOW = {
            error: !approvalFlowValidation.valid,
            cards: approvalFlowValidation.cardErrors,
        };
        if (!approvalFlowValidation.valid) {
            valid = false;
        }

        setDocTypeVersionError(errorData);

        if (valid) {
            const res = await publishDocumentType(documentTypeId, documentTypeVersionId);
            if (res?.apiStatus === 'SUCCESS') {
                toast.success(DOC_TYPE_PROVIDER.TOAST.SUCCESS_MSG.DOCUMENT_PUBLISHED);
                navigate(`/${CONSTANTS.RELATIVE_PATHS.adminDocTypes.Url}`);
            } else {
                toast.error(DOC_TYPE_PROVIDER.TOAST.ERROR_MSG.UNABLE_TO_PUBLISH);
            }
        }
        setPublishBtnLoading(false);
    };

    useEffect(() => {
        fetchDocTypeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getQueryParam, docTypeInfo.version]);

    return (
        <DocTypeContext.Provider
            value={{
                documentTypeId,
                fields,
                docTypeInfo,
                attachmentConfig,
                docNumbering,
                approvalStages,
                publishBtnLoading,
                docTypeVersions,
                docTypeConfig,
                docTypeVersionError,
                docTypeErrors,
                docTypeDispatch,
                docNumberDispatch,
                attachmentConfigDispatch,
                onSaveDraft,
                onSubmit,
                changeDocTypeInfo,
                changeMaxRepublishCount,
                changeDefaultApprovalDeadline,
                approvalStageDispatch,
                setDocTypeConfig,
                generationTrigger,
                setGenerationTrigger,
            }}
        >{children}</DocTypeContext.Provider>
    );
};

export const useDocTypeContext = (): IDocumentTypeContext => useContext(DocTypeContext);

export default DocumentTypeProvider;
