import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import CONSTANTS from '../../constants';
import CustomReportModal from '../../widgets/reports/CustomReportModal';
import ReportActivityTableTab from './ActivityTab';
import './ReportDetailSummary.scss';
import ReportRunsTableTab from './RunsTab';
import ReportSummaryTab from './SummaryTab';
import RunsTabPage from './RunsTabPage';

// eslint-disable-next-line max-lines-per-function
const ReportDetailSummary: React.FC = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportName = queryParams.get('report_name');
    const [activeTab, setActiveTab] = useState('Summary');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const tabChangeHandler = (type: string) => {
        setActiveTab(type);
    };
    return (
        <div className="reports-detail-summary-page">
            <div className="report-title">
                <div>
                    <h1>{reportName}</h1>
                    {/* <span className="status-pill shared">Shared</span> */}
                </div>
                {/* <div>
                    <button type="button" className="btn" onClick={() => setShowModal(true)}>
                        Edit Report
                    </button>
                    <button
                        type="button"
                        className="btn"
                        onClick={() =>
                            navigate(`/reportList/report?prev_page_type=CUSTOM_REPORT`)
                        }
                    >
                        Run
                    </button>
                </div> */}
            </div>
            {/* {activeTab ? (
                <div className="tab">
                    <button
                        id="Summary"
                        type="button"
                        aria-label="Summary"
                        className={`${activeTab === 'Summary' ? 'active' : ''}`}
                        onClick={() => tabChangeHandler('Summary')}
                    >
                        <span className="name">Summary</span>
                    </button>
                    <button
                        id="Recent_Runs"
                        type="button"
                        aria-label="Recent_Runs"
                        className={`${activeTab === 'Recent_Runs' ? 'active' : ''}`}
                        onClick={() => tabChangeHandler('Recent_Runs')}
                    >
                        <span className="name">Recent Runs</span>
                    </button>
                    <button
                        id="Recent_Activity"
                        type="button"
                        aria-label="Recent_Activity"
                        className={`${activeTab === 'Recent_Activity' ? 'active' : ''}`}
                        onClick={() => tabChangeHandler('Recent_Activity')}
                    >
                        <span className="name">Recent Activity</span>
                    </button>
                </div>
            ) : (
                <div className="puff-loader">
                    <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
                </div>
            )} */}
            <div>
                {/* {activeTab === 'Summary' && <ReportSummaryTab />}
                {activeTab === 'Recent_Runs' && <ReportRunsTableTab />}
                {activeTab === 'Recent_Activity' && <ReportActivityTableTab />} */}
                <RunsTabPage />
            </div>
            {/* <CustomReportModal isModalOpen={showModal} onModalClose={setShowModal} /> */}
        </div>
    );
};

export default ReportDetailSummary;