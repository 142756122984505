/* eslint-disable react/no-array-index-key */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

import ReactDatePicker from 'react-datepicker';
import {
    createDelegation,
    getEmployeeDelegator,
    getEmployeesDelegatee,
    removeDelegation,
    userAutoSearch,
} from '../../API/delegation';
import { DeleteSvg } from '../../assets/images';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IOptionValue } from '../../interfaces';
import { IDelegate, IDelegationInfo } from '../../interfaces/delegation';
import { convertToEpoch, getNextDate } from '../../utils';
import { formatDate } from '../../utils/date';
import './DelegationSetting.scss';
import STATIC_CONTENT from '../../constants/StaticContent';
import CONSTANTS from '../../constants';

const { DELEGATION_SETTINGS } = STATIC_CONTENT;

const DelegationSetting: React.FC = () => {
    const { user: employee } = useAuthDataContext();
    const today = new Date();
    const tomorrow = getNextDate(today);
    const dateFormat = 'MMM d, yyyy';

    const defaultNewDelegate: IDelegate = {
        user: { value: 0, label: '' },
        formDate: today,
        toDate: tomorrow,
        editAccess: false,
        approvalAccess: true,
    };

    const [newDelegation, setNewDelegation] = useState(defaultNewDelegate);
    const [usersLoading, setUsersLoading] = useState(false);
    const [delegateeList, setDelegateeList] = useState<IDelegationInfo[]>([]);
    const [delegatorList, setDelegatorList] = useState<IDelegationInfo[]>([]);
    const [searchText, setSearchText] = useState('');

    const handleFromDate = (date: Date | null) => {
        if(date)
        {
            if (newDelegation.toDate <= date) {
                setNewDelegation({ ...newDelegation, formDate: date, toDate: getNextDate(date) });
            } else {
                setNewDelegation({ ...newDelegation, formDate: date });
            }
        }
    };
    const handleToDate = (date: Date | null) => {
        if(date)
        {
            setNewDelegation({ ...newDelegation, toDate: date })
        }
    };
    const handleUser = (user: IOptionValue) => setNewDelegation({ ...newDelegation, user });

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setUsersLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                let users = await userAutoSearch(inputValue, 'MANAGER');
                if (users) {
                    users = users.filter((u) => u.value !== employee.id);
                    resolve(users);
                    setUsersLoading(false);
                }
            } else {
                setUsersLoading(false);
            }
        });
    };

    const UpdateDelegateeList = async () => {
        const list = await getEmployeesDelegatee(employee.id);
        if (list) {
            setDelegateeList(list);
        }
    };

    const UpdateDelegatorList = async () => {
        const list = await getEmployeeDelegator(employee.id);
        if (list) {
            setDelegatorList(list);
        }
    };

    useEffect(() => {
        UpdateDelegateeList();
        UpdateDelegatorList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStart = async () => {
        if (newDelegation.formDate > newDelegation.toDate) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.INVALID_DATES);
        } else if (!newDelegation.user.value) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.USER_ALREADY_DELEGATED);
        } else {
            const formData = {
                delegatorId: employee.id,
                delegateeId: newDelegation.user.value,
                startTime: convertToEpoch(newDelegation.formDate),
                endTime: convertToEpoch(newDelegation.toDate),
                editAccess: newDelegation.editAccess,
                approvalAccess: newDelegation.approvalAccess,
            };
            const responseData = await createDelegation(formData);
            if (responseData?.apiStatus === 'SUCCESS') {
                toast.success(DELEGATION_SETTINGS.TOAST.SUCCESS_MSG.DELEGATED);
                UpdateDelegateeList();
                UpdateDelegatorList();
            } else if (responseData?.error.includes('MULTIPLE_DELEGATIONS_FOR_DELEGATOR')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.MULTIPLE_DELEGATIONS_FOR_DELEGATOR);
            } else if (responseData?.error.includes('DELEGATEE_UNAVAILABLE')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.DELEGATEE_UNAVAILABLE);
            } else {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
        }
    };

    const deleteDelegation = async (id: number) => {
        const response = await removeDelegation(id);
        if (response?.apiStatus === 'SUCCESS') {
            UpdateDelegatorList();
        } else {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    };

    return (
        <div className="delegate-goals">
            <div className="delegation-widget">
                <div className="from-row">
                    <div className="form-group">
                        <label>{DELEGATION_SETTINGS.LABEL.DELEGATE_TO}</label>
                        <AsyncSelect
                            name="ownerId"
                            id="ownerId"
                            className="react-select-delegate"
                            classNamePrefix="select"
                            value={newDelegation.user}
                            isLoading={usersLoading}
                            hideSelectedOptions
                            loadOptions={promiseOptions}
                            onChange={(option) => {
                                if (option) handleUser(option);
                            }}
                            onFocus={() =>
                                setNewDelegation({
                                    ...newDelegation,
                                    user: { value: 0, label: '' },
                                })
                            }
                            noOptionsMessage={() =>
                                searchText.length >= 3
                                    ? 'No results found'
                                    : 'Start typing minimum 3 characters'
                            }
                        />
                    </div>
                    <div className="form-group">
                        <label>{DELEGATION_SETTINGS.LABEL.FROM_DATE}</label>
                        <ReactDatePicker
                            className="form-control"
                            selected={newDelegation.formDate}
                            onChange={handleFromDate}
                            dateFormat={dateFormat}
                            selectsStart
                            startDate={newDelegation.formDate}
                            endDate={newDelegation.toDate}
                            minDate={new Date()}
                        />
                    </div>
                    <div className="form-group">
                        <label>{DELEGATION_SETTINGS.LABEL.TO_DATE}</label>
                        <ReactDatePicker
                            className="form-control"
                            selected={newDelegation.toDate}
                            onChange={handleToDate}
                            dateFormat={dateFormat}
                            selectsEnd
                            startDate={newDelegation.formDate}
                            endDate={newDelegation.toDate}
                            minDate={getNextDate(newDelegation.formDate)}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <button type="button" className="primary-btn" onClick={() => handleStart()}>
                        {DELEGATION_SETTINGS.BUTTON.SAVE}
                    </button>
                </div>
            </div>
            {delegateeList.length > 0 ? (
                <div className="delegation-history">
                    <h3>{DELEGATION_SETTINGS.LABEL.DELEGATED_FOR_ME}</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{DELEGATION_SETTINGS.LABEL.DELEGATED_FROM}</th>
                                <th>{DELEGATION_SETTINGS.LABEL.FROM}</th>
                                <th>{DELEGATION_SETTINGS.LABEL.TO}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {delegateeList.map((d, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.employeeName}</td>
                                    <td>{formatDate(d.fromDate)}</td>
                                    <td>{formatDate(d.toDate)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}

            {delegatorList.length > 0 ? (
                <div className="delegation-history">
                    <h3>{DELEGATION_SETTINGS.LABEL.DELEGATED_BY_ME}</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{DELEGATION_SETTINGS.LABEL.DELEGATED_TO}</th>
                                <th>{DELEGATION_SETTINGS.LABEL.FROM}</th>
                                <th>{DELEGATION_SETTINGS.LABEL.TO}</th>
                                <th>{}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {delegatorList.map((d, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.employeeName}</td>
                                    <td>{formatDate(d.fromDate)}</td>
                                    <td>{formatDate(d.toDate)}</td>
                                    <td>
                                        <DeleteSvg
                                            onClick={() => {
                                                deleteDelegation(d.id);
                                            }}
                                            style={{ fontSize: '20px' }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : null}
        </div>
    );
};

export default DelegationSetting;
